import React, { Component } from 'react'
import Header from '../../common/header/header'
import Footer from '../../common/footer/footer'

export default class Page2 extends Component {
  render() {
    return (
      <div>
        <Header />
        <main>
          <section className="block-table tab-wrap">
            <div className="table-wrap table-wrap__w830">
              <div className="table-info">
                <h2>SWEEPSTAKE <span>#69</span></h2>
                <h3>CASH PRIZE POOL: <span>$750</span></h3>
                <p>Sweepstake End Date: 3 Mar ‘21</p>
              </div>
              <div className="table winner-table">
                <div className="t-head">
                  <div className="t-user">Username</div>
                  <div className="t-prize">Prize</div>
                  <div className="t-cash">Cash Prize</div>
                </div>
                <div className="t-row my-position">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-9.jpg" alt="" />
                            You
                        </a>
                  </div>
                  <div className="t-prize">16°</div>
                  <div className="t-cash">
                    <div className="my-cash">$4 <img src="images/icons/cash-icon.png" alt="" /></div>
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-4.jpg" alt="" />
                            Pablo
                        </a>
                  </div>
                  <div className="t-prize">1°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-5.jpg" alt="" />
                            Claudio
                        </a>
                  </div>
                  <div className="t-prize">2°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-6.jpg" alt="" />
                            Merith
                        </a>
                  </div>
                  <div className="t-prize">3°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-7.jpg" alt="" />
                            Jekis
                        </a>
                  </div>
                  <div className="t-prize">4°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-8.jpg" alt="" />
                            Mark
                        </a>
                  </div>
                  <div className="t-prize">5°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-4.jpg" alt="" />
                            Pablo
                        </a>
                  </div>
                  <div className="t-prize">1°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-5.jpg" alt="" />
                            Claudio
                        </a>
                  </div>
                  <div className="t-prize">2°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-6.jpg" alt="" />
                            Merith
                        </a>
                  </div>
                  <div className="t-prize">3°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-7.jpg" alt="" />
                            Jekis
                        </a>
                  </div>
                  <div className="t-prize">4°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
                <div className="t-row">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="images/photos/photo-8.jpg" alt="" />
                            Mark
                        </a>
                  </div>
                  <div className="t-prize">5°</div>
                  <div className="t-cash color-green">$750</div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    )
  }
}
