import { TimeZones } from './timezones';

const dateHelper = {};
dateHelper.getTimeZone = tz => (TimeZones.filter((item) => { // eslint-disable-line
  if (item && item.value === tz) {
    return true;
  }
})[0]);

export default dateHelper;
