import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { Redirect } from 'react-router-dom'
import { GET_ERRORS, SET_CURRENT_USER, SET_PROFILE_IMG, SET_AUTH, CHANGE_USER_NICKNAME } from './types';
import { reloadComponentWithSuccess } from '../common/lib';
import { setProfileLoading, clearProfileLoading } from './profileActions';
import $ from 'jquery';
import S3 from 'react-aws-s3';


let backendServerURL = process.env.REACT_APP_URL;
const config = {
    bucketName: 'playtoobucket',
    region: 'eu-west-2',
    accessKeyId: 'AKIATUOMMYVDTL4XN2U6',
    secretAccessKey: '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',
}
// Login - Get User Token (Verified)
export const loginJwt = (userData, history) => dispatch => {
    console.log('userData payload', userData)
    dispatch(setProfileLoading());
    axios
        .post(
            backendServerURL + 'auth/login',
            userData
        )
        .then(res => {
            const { token, user } = res.data;
            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(user));
            history.push('/')
            dispatch(isAuthenticated(true));
        })
        .catch(err => {
            console.log('AUTH ERR', err)

            dispatch({ type: GET_ERRORS, payload: err })
        })
        .finally(() => dispatch(clearProfileLoading()));
};

//Forgot Password
export const forgotPassword = (userData, history) => dispatch => {
    dispatch(setProfileLoading());
    axios
        .post(
            backendServerURL + 'auth/forgot-password',
            userData
        )
        .then(res => {
            const { token } = res.data;

            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            history.push('/sign-in')
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, payload: err.response.data })
        })
        .finally(() => dispatch(clearProfileLoading()));
};

//Recover Password
export const sendPasswordRecoveryLink = (userData, history) => dispatch => {
    dispatch(setProfileLoading());
    axios
        .post(
            backendServerURL + 'auth/recover-password-email',
            userData
        )
        .then(res => {
            if (res.data.success) {
                reloadComponentWithSuccess();
            }
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, payload: err.response.data })
        })
        .finally(() => dispatch(clearProfileLoading()));
};

//Reset Password
export const resetUserPassword = (inputData, verificationToken, history) => dispatch => {
    dispatch(setProfileLoading());
    axios
        .post(
            backendServerURL + 'auth/reset-user-password',
            {
                token: verificationToken,
                passwordData: inputData
            }
        )
        .then(res => {
            history.push('sign-in?reset=true');
        })
        .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(clearProfileLoading()));
};

// Login with facebook
export const loginWithFacebook = (userData, history) => dispatch => {
    dispatch(setProfileLoading());
    axios.post(backendServerURL + 'auth/facebook', userData)
        .then(res => {
            console.log(res)
            const { token } = res.data;

            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            history.push('/dashboard')
        })
        .catch(err => {
            console.log(err)
            dispatch({ type: GET_ERRORS, payload: err.response.data })
        })
        .finally(() => dispatch(clearProfileLoading()));
};

// Login with google
export const loginWithGoogle = (userData, history) => dispatch => {
    dispatch(setProfileLoading());
    axios.post(backendServerURL + 'auth/google', userData)
        .then(res => {
            console.log(res)
            const { token } = res.data;

            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            history.push('/dashboard')
        })
        .catch(err => {
            console.log(err)
            dispatch({ type: GET_ERRORS, payload: err.response.data })
        })
        .finally(() => dispatch(clearProfileLoading()));
};

// Register User (Verified)
export const registerUser = (userData, history) => dispatch => {
    dispatch(setProfileLoading());
    const { image, ...rest } = userData;
    // debugger
    if (image !== "") {
        const ReactS3Client = new S3(config);

        // const newFileName = 'Profile Picture';
        ReactS3Client
            .uploadFile(image)
            .then((data) => {
                const profileUrl = data.location
                rest.imageUrl = profileUrl
                // debugger
                const regData = rest
                console.log("this is the url from s3", regData)
                axios
                    .post(
                        backendServerURL + 'auth/signup',
                        // newProfile,
                        regData
                    )
                    .then(res => {
                        console.log("this is the response", res)
                        const { token } = res.data;
                        // debugger
                        localStorage.setItem('jwtToken', token);
                        setAuthToken(token);
                        // const decoded = jwt_decode(token);
                        const { user } = res.data;
                        dispatch(setCurrentUser(user));
                        if (res.status = 200) {

                            <Redirect to='/dashboard' />
                            // history.push('/dashboard')
                        }
                    })
                    .catch(err => {
                        console.log(err.response.data)
                        dispatch({ type: GET_ERRORS, payload: err.response.data })
                    })
                    .finally(() => dispatch(clearProfileLoading()));
            })
    } else {
        axios
            .post(
                backendServerURL + 'auth/signup',
                // newProfile,
                rest
            )
            .then(res => {
                console.log("this is the response", res)
                const { token } = res.data;
                // debugger
                localStorage.setItem('jwtToken', token);
                setAuthToken(token);
                // const decoded = jwt_decode(token);
                const { user } = res.data;
                dispatch(setCurrentUser(user));
                if (res.status = 200) {

                    <Redirect to='/dashboard' />
                    // history.push('/dashboard')
                }
            })
            .catch(err => {
                console.log(err.response.data)
                dispatch({ type: GET_ERRORS, payload: err.response.data })
            })
            .finally(() => dispatch(clearProfileLoading()));
    }
}
//GET USer Information
export const getCurrentUser = () => dispatch => {

    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            backendServerURL + 'auth/getUserData',
            options
        )
        .then(res => {
            console.log('getUser Status Payload', res)
            const { user } = res.data;

            dispatch(setCurrentUser(user));
            dispatch(isAuthenticated(true));


        })
        .catch(err => {
            // debugger
            console.log(' getCurrentUser RESPONSE ERR', err)

        })

};

export const returnCurrentUser = () => dispatch => {

    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            backendServerURL + 'auth/getUserData',
            options
        )
        .then(res => {
            console.log('getUser Status Payload', res)
            const { user } = res.data;

            return user


        })
        .catch(err => {
            // debugger
            console.log(' getCurrentUser RESPONSE ERR', err)

        })

};
// Profile Img State   ////
export const setProfileImg = img => {
    return {
        type: SET_PROFILE_IMG,
        payload: img
    };
};
// Set logged in user (Verified)
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};
export const isAuthenticated = data => {
    return {
        type: SET_AUTH,
        payload: data
    };
};



// Log user out (Verified)
export const logoutUser = history => dispatch => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);

    dispatch(setCurrentUser({}));
    window.location.href = '/'
    // history.push('/');
};


export const changeNickname = (userData) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .post(
            backendServerURL + 'admin/updateUserProfile',
            userData,
            options
        )
        .then(res => {
            const { data } = res.data
            dispatch(setCurrentUser(data));
            //window.location.href = '/'
            //history.push('/dashboard')
        })
        .catch(err => {
            console.log(err.response.data)
            dispatch({ type: GET_ERRORS, payload: err.response.data })
        })


}

// Change Current Password
export const changePassword = (data) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    axios
        .post(
            backendServerURL + 'auth/change-user-password',
            data,
            options
        )
        .then(res => {
            console.log("this is the response from request", res)
            const { data } = res.data
            dispatch(setCurrentUser(data));

        })
        .catch(err => {
            console.log(err.response.data)
            dispatch({ type: GET_ERRORS, payload: err.response.data })
        })

}