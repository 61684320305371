export const TimeZones = [ // eslint-disable-line
  { value: 'Africa/Abidjan', label: 'Abidjan - Ivory Coast' },
  { value: 'Africa/Accra', label: 'Accra - Ghana' },
  { value: 'America/Adak', label: 'Adak - United States' },
  { value: 'Africa/Addis_Ababa', label: 'Addis Ababa - Ethiopia' },
  { value: 'Asia/Aden', label: 'Aden - Yemen' },
  { value: 'Africa/Algiers', label: 'Algiers - Algeria' },
  { value: 'Asia/Almaty', label: 'Almaty - Kazakhstan' },
  { value: 'Asia/Amman', label: 'Amman - Jordan' },
  { value: 'Europe/Amsterdam', label: 'Amsterdam - Netherlands' },
  { value: 'Asia/Anadyr', label: 'Anadyr - Russia' },
  { value: 'America/Anchorage', label: 'Anchorage - United States' },
  { value: 'Europe/Andorra', label: 'Andorra - Andorra' },
  { value: 'America/Anguilla', label: 'Anguilla - Anguilla' },
  { value: 'Indian/Antananarivo', label: 'Antananarivo - Madagascar' },
  { value: 'America/Antigua', label: 'Antigua - Antigua and Barbuda' },
  { value: 'Pacific/Apia', label: 'Apia - Samoa' },
  { value: 'Asia/Aqtau', label: 'Aqtau - Kazakhstan' },
  { value: 'Asia/Aqtobe', label: 'Aqtobe - Kazakhstan' },
  { value: 'America/Araguaina', label: 'Araguaina - Brazil' },
  { value: 'America/Aruba', label: 'Aruba - Aruba' },
  { value: 'Asia/Ashgabat', label: 'Ashgabat - Turkmenistan' },
  { value: 'Africa/Asmara', label: 'Asmara - Eritrea' },
  { value: 'Europe/Astrakhan', label: 'Astrakhan - Russia' },
  { value: 'America/Asuncion', label: 'Asuncion - Paraguay' },
  { value: 'Europe/Athens', label: 'Athens - Greece' },
  { value: 'America/Atikokan', label: 'Atikokan - Canada' },
  { value: 'America/New_York', label: 'Atlanta - United States' },
  { value: 'Asia/Atyrau', label: 'Atyrau - Kazakhstan' },
  { value: 'Pacific/Auckland', label: 'Auckland - New Zealand' },
  { value: 'Atlantic/Azores', label: 'Azores - Portugal' },
  { value: 'Asia/Baghdad', label: 'Baghdad - Iraq' },
  { value: 'America/Bahia', label: 'Bahia - Brazil' },
  { value: 'America/Bahia_Banderas', label: 'Bahia Banderas - Mexico' },
  { value: 'Asia/Bahrain', label: 'Bahrain - Bahrain' },
  { value: 'Asia/Baku', label: 'Baku - Azerbaijan' },
  { value: 'Africa/Bamako', label: 'Bamako - Mali' },
  { value: 'Asia/Bangkok', label: 'Bangkok - Thailand' },
  { value: 'Africa/Bangui', label: 'Bangui - Central African Republic' },
  { value: 'Africa/Banjul', label: 'Banjul - Gambia' },
  { value: 'America/Barbados', label: 'Barbados - Barbados' },
  { value: 'Asia/Barnaul', label: 'Barnaul - Russia' },
  { value: 'Asia/Hong_Kong', label: 'Beijing - China' },
  { value: 'Asia/Beirut', label: 'Beirut - Lebanon' },
  { value: 'America/Belem', label: 'Belem - Brazil' },
  { value: 'Europe/Belgrade', label: 'Belgrade - Serbia' },
  { value: 'America/Belize', label: 'Belize - Belize' },
  { value: 'Europe/Berlin', label: 'Berlin - Germany' },
  { value: 'Asia/Kolkata', label: 'Bengalore - India' },
  { value: 'Atlantic/Bermuda', label: 'Bermuda - Bermuda' },
  { value: 'Australia/Darwin', label: 'Beulah - Australia' },
  { value: 'America/North_Dakota/Beulah', label: 'Beulah - United States' },
  { value: 'Asia/Bishkek', label: 'Bishkek - Kyrgyzstan' },
  { value: 'Africa/Bissau', label: 'Bissau - Guinea-Bissau' },
  { value: 'America/Blanc-Sablon', label: 'Blanc-Sablon - Canada' },
  { value: 'Africa/Blantyre', label: 'Blantyre - Malawi' },
  { value: 'America/Boa_Vista', label: 'Boa Vista - Brazil' },
  { value: 'America/Bogota', label: 'Bogota - Colombia' },
  { value: 'America/Boise', label: 'Boise - United States' },
  { value: 'Pacific/Bougainville', label: 'Bougainville - Papua New Guinea' },
  { value: 'Europe/Bratislava', label: 'Bratislava - Slovakia' },
  { value: 'Africa/Brazzaville', label: 'Brazzaville - Republic of the Congo' },
  { value: 'Australia/Brisbane', label: 'Brisbane - Australia' },
  { value: 'Asia/Brunei', label: 'Brunei - Brunei' },
  { value: 'Europe/Brussels', label: 'Brussels - Belgium' },
  { value: 'Europe/Bucharest', label: 'Bucharest - Romania' },
  { value: 'Europe/Budapest', label: 'Budapest - Hungary' },
  { value: 'America/Argentina/Buenos_Aires', label: 'Buenos Aires - Argentina' },
  { value: 'Africa/Bujumbura', label: 'Bujumbura - Burundi' },
  { value: 'Europe/Busingen', label: 'Busingen - Germany' },
  { value: 'Africa/Cairo', label: 'Cairo - Egypt' },
  { value: 'America/Cambridge_Bay', label: 'Cambridge Bay - Canada' },
  { value: 'America/Campo_Grande', label: 'Campo Grande - Brazil' },
  { value: 'Atlantic/Canary', label: 'Canary - Spain' },
  { value: 'America/Cancun', label: 'Cancun - Mexico' },
  { value: 'Atlantic/Cape_Verde', label: 'Cape Verde - Cape Verde' },
  { value: 'America/Caracas', label: 'Caracas - Venezuela' },
  { value: 'Africa/Casablanca', label: 'Casablanca - Morocco' },
  { value: 'Antarctica/Casey', label: 'Casey - Antarctica' },
  { value: 'America/Argentina/Catamarca', label: 'Catamarca - Argentina' },
  { value: 'America/Cayenne', label: 'Cayenne - French Guiana' },
  { value: 'America/Cayman', label: 'Cayman - Cayman Islands' },
  { value: 'Australia/Lindeman', label: 'Center - Australia' },
  { value: 'America/North_Dakota/Center', label: 'Center - United States' },
  { value: 'Africa/Ceuta', label: 'Ceuta - Spain' },
  { value: 'Indian/Chagos', label: 'Chagos - British Indian Ocean Territory' },
  { value: 'Pacific/Chatham', label: 'Chatham - New Zealand' },
  { value: 'America/Chicago', label: 'Chicago - United States' },
  { value: 'America/Chihuahua', label: 'Chihuahua - Mexico' },
  { value: 'Europe/Chisinau', label: 'Chisinau - Moldova' },
  { value: 'Asia/Chita', label: 'Chita - Russia' },
  { value: 'Asia/Choibalsan', label: 'Choibalsan - Mongolia' },
  { value: 'Indian/Christmas', label: 'Christmas - Christmas Island' },
  { value: 'Pacific/Chuuk', label: 'Chuuk - Micronesia' },
  { value: 'Indian/Cocos', label: 'Cocos - Cocos Islands' },
  { value: 'Asia/Colombo', label: 'Colombo - Sri Lanka' },
  { value: 'America/New_York', label: 'Columbus - United States' },
  { value: 'Indian/Comoro', label: 'Comoro - Comoros' },
  { value: 'Africa/Conakry', label: 'Conakry - Guinea' },
  { value: 'Europe/Copenhagen', label: 'Copenhagen - Denmark' },
  { value: 'America/Argentina/Cordoba', label: 'Cordoba - Argentina' },
  { value: 'America/Costa_Rica', label: 'Costa Rica - Costa Rica' },
  { value: 'America/Creston', label: 'Creston - Canada' },
  { value: 'America/Cuiaba', label: 'Cuiaba - Brazil' },
  { value: 'America/Curacao', label: 'Curacao - CuraÃ§ao' },
  { value: 'Africa/Dakar', label: 'Dakar - Senegal' },
  { value: 'Asia/Damascus', label: 'Damascus - Syria' },
  { value: 'America/Danmarkshavn', label: 'Danmarkshavn - Greenland' },
  { value: 'Africa/Dar_es_Salaam', label: 'Dar es Salaam - Tanzania' },
  { value: 'Antarctica/Davis', label: 'Davis - Antarctica' },
  { value: 'America/Dawson', label: 'Dawson - Canada' },
  { value: 'America/Dawson_Creek', label: 'Dawson Creek - Canada' },
  { value: 'America/Denver', label: 'Denver - United States' },
  { value: 'America/Detroit', label: 'Detroit - United States' },
  { value: 'Asia/Dhaka', label: 'Dhaka - Bangladesh' },
  { value: 'Asia/Dili', label: 'Dili - East Timor' },
  { value: 'Africa/Djibouti', label: 'Djibouti - Djibouti' },
  { value: 'America/Dominica', label: 'Dominica - Dominica' },
  { value: 'Africa/Douala', label: 'Douala - Cameroon' },
  { value: 'Asia/Dubai', label: 'Dubai - United Arab Emirates' },
  { value: 'Europe/Dublin', label: 'Dublin - Ireland' },
  { value: 'Antarctica/DumontDUrville', label: 'DumontDUrville - Antarctica' },
  { value: 'Asia/Dushanbe', label: 'Dushanbe - Tajikistan' },
  { value: 'Pacific/Easter', label: 'Easter - Chile' },
  { value: 'America/Edmonton', label: 'Edmonton - Canada' },
  { value: 'Pacific/Efate', label: 'Efate - Vanuatu' },
  { value: 'America/Eirunepe', label: 'Eirunepe - Brazil' },
  { value: 'Africa/El_Aaiun', label: 'El Aaiun - Western Sahara' },
  { value: 'America/El_Salvador', label: 'El Salvador - El Salvador' },
  { value: 'Pacific/Enderbury', label: 'Enderbury - Kiribati' },
  { value: 'Australia/Eucla', label: 'Eucla - Australia' },
  { value: 'Pacific/Fakaofo', label: 'Fakaofo - Tokelau' },
  { value: 'Asia/Famagusta', label: 'Famagusta - Cyprus' },
  { value: 'Atlantic/Faroe', label: 'Faroe - Faroe Islands' },
  { value: 'Pacific/Fiji', label: 'Fiji - Fiji' },
  { value: 'America/Fort_Nelson', label: 'Fort Nelson - Canada' },
  { value: 'America/Fortaleza', label: 'Fortaleza - Brazil' },
  { value: 'Africa/Freetown', label: 'Freetown - Sierra Leone' },
  { value: 'Pacific/Funafuti', label: 'Funafuti - Tuvalu' },
  { value: 'Africa/Gaborone', label: 'Gaborone - Botswana' },
  { value: 'Pacific/Galapagos', label: 'Galapagos - Ecuador' },
  { value: 'Pacific/Gambier', label: 'Gambier - French Polynesia' },
  { value: 'Asia/Gaza', label: 'Gaza - Palestinian Territory' },
  { value: 'Europe/Gibraltar', label: 'Gibraltar - Gibraltar' },
  { value: 'America/Glace_Bay', label: 'Glace Bay - Canada' },
  { value: 'America/Godthab', label: 'Godthab - Greenland' },
  { value: 'America/Goose_Bay', label: 'Goose Bay - Canada' },
  { value: 'America/Grand_Turk', label: 'Grand Turk - Turks and Caicos Islands' },
  { value: 'America/Grenada', label: 'Grenada - Grenada' },
  { value: 'Pacific/Guadalcanal', label: 'Guadalcanal - Solomon Islands' },
  { value: 'America/Guadeloupe', label: 'Guadeloupe - Guadeloupe' },
  { value: 'Pacific/Guam', label: 'Guam - Guam' },
  { value: 'America/Guatemala', label: 'Guatemala - Guatemala' },
  { value: 'America/Guayaquil', label: 'Guayaquil - Ecuador' },
  { value: 'Europe/Guernsey', label: 'Guernsey - Guernsey' },
  { value: 'America/Guyana', label: 'Guyana - Guyana' },
  { value: 'America/Halifax', label: 'Halifax - Canada' },
  { value: 'Africa/Harare', label: 'Harare - Zimbabwe' },
  { value: 'America/Havana', label: 'Havana - Cuba' },
  { value: 'Asia/Hebron', label: 'Hebron - Palestinian Territory' },
  { value: 'Europe/Helsinki', label: 'Helsinki - Finland' },
  { value: 'America/Hermosillo', label: 'Hermosillo - Mexico' },
  { value: 'Asia/Ho_Chi_Minh', label: 'Ho Chi Minh - Vietnam' },
  { value: 'Asia/Hong_Kong', label: 'Hong Kong - Hong Kong' },
  { value: 'Pacific/Honolulu', label: 'Honolulu - United States' },
  { value: 'Asia/Hovd', label: 'Hovd - Mongolia' },
  { value: 'Australia/Lord_Howe', label: 'Indianapolis - Australia' },
  { value: 'America/Indiana/Indianapolis', label: 'Indianapolis - United States' },
  { value: 'America/Inuvik', label: 'Inuvik - Canada' },
  { value: 'America/Iqaluit', label: 'Iqaluit - Canada' },
  { value: 'Asia/Irkutsk', label: 'Irkutsk - Russia' },
  { value: 'Asia/Karachi', label: 'Islamabad - Pakistan' },
  { value: 'Europe/Isle_of_Man', label: 'Isle of Man - Isle of Man' },
  { value: 'Europe/Istanbul', label: 'Istanbul - Turkey' },
  { value: 'Asia/Jakarta', label: 'Jakarta - Indonesia' },
  { value: 'America/Jamaica', label: 'Jamaica - Jamaica' },
  { value: 'Asia/Jayapura', label: 'Jayapura - Indonesia' },
  { value: 'Europe/Jersey', label: 'Jersey - Jersey' },
  { value: 'Asia/Jerusalem', label: 'Jerusalem - Israel' },
  { value: 'Africa/Johannesburg', label: 'Johannesburg - South Africa' },
  { value: 'Africa/Juba', label: 'Juba - South Sudan' },
  { value: 'America/Argentina/Jujuy', label: 'Jujuy - Argentina' },
  { value: 'America/Juneau', label: 'Juneau - United States' },
  { value: 'Asia/Kabul', label: 'Kabul - Afghanistan' },
  { value: 'Europe/Kaliningrad', label: 'Kaliningrad - Russia' },
  { value: 'Asia/Kamchatka', label: 'Kamchatka - Russia' },
  { value: 'Africa/Kampala', label: 'Kampala - Uganda' },
  { value: 'Asia/Karachi', label: 'Karachi - Pakistan' },
  { value: 'Asia/Kathmandu', label: 'Kathmandu - Nepal' },
  { value: 'Europe/Moscow', label: 'Kazan - Russia' },
  { value: 'Indian/Kerguelen', label: 'Kerguelen - French Southern Territories' },
  { value: 'Asia/Khandyga', label: 'Khandyga - Russia' },
  { value: 'Africa/Khartoum', label: 'Khartoum - Sudan' },
  { value: 'Europe/Kiev', label: 'Kiev - Ukraine' },
  { value: 'Africa/Kigali', label: 'Kigali - Rwanda' },
  { value: 'Africa/Kinshasa', label: 'Kinshasa - Democratic Republic of the Congo' },
  { value: 'Pacific/Kiritimati', label: 'Kiritimati - Kiribati' },
  { value: 'Europe/Kirov', label: 'Kirov - Russia' },
  { value: 'Australia/Brisbane', label: 'Knox - Australia' },
  { value: 'America/Indiana/Knox', label: 'Knox - United States' },
  { value: 'Asia/Kolkata', label: 'Kolkata - India' },
  { value: 'Asia/Calcutta', label: 'Kolkata - India' },
  { value: 'Pacific/Kosrae', label: 'Kosrae - Micronesia' },
  { value: 'America/Kralendijk', label: 'Kralendijk - Bonaire, Saint Eustatius and Saba ' },
  { value: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk - Russia' },
  { value: 'Asia/Kuala_Lumpur', label: 'Kuala Lumpur - Malaysia' },
  { value: 'Asia/Kuching', label: 'Kuching - Malaysia' },
  { value: 'Asia/Kuwait', label: 'Kuwait - Kuwait' },
  { value: 'Pacific/Kwajalein', label: 'Kwajalein - Marshall Islands' },
  { value: 'America/La_Paz', label: 'La Paz - Bolivia' },
  { value: 'America/Argentina/La_Rioja', label: 'La Rioja - Argentina' },
  { value: 'Africa/Lagos', label: 'Lagos - Nigeria' },
  { value: 'Africa/Libreville', label: 'Libreville - Gabon' },
  { value: 'America/Lima', label: 'Lima - Peru' },
  { value: 'Europe/Lisbon', label: 'Lisbon - Portugal' },
  { value: 'Europe/Ljubljana', label: 'Ljubljana - Slovenia' },
  { value: 'Africa/Lome', label: 'Lome - Togo' },
  { value: 'Europe/London', label: 'London - United Kingdom' },
  { value: 'Arctic/Longyearbyen', label: 'Longyearbyen - Svalbard and Jan Mayen' },
  { value: 'America/Los_Angeles', label: 'Los Angeles - United States' },
  { value: 'Pacific/Pago_Pago', label: 'Louisville - American Samoa' },
  { value: 'America/Kentucky/Louisville', label: 'Louisville - United States' },
  { value: 'America/Lower_Princes', label: 'Lower Princes - Sint Maarten' },
  { value: 'Africa/Luanda', label: 'Luanda - Angola' },
  { value: 'Africa/Lubumbashi', label: 'Lubumbashi - Democratic Republic of the Congo' },
  { value: 'Africa/Lusaka', label: 'Lusaka - Zambia' },
  { value: 'Europe/Luxembourg', label: 'Luxembourg - Luxembourg' },
  { value: 'Asia/Macau', label: 'Macau - Macao' },
  { value: 'America/Maceio', label: 'Maceio - Brazil' },
  { value: 'Atlantic/Madeira', label: 'Madeira - Portugal' },
  { value: 'Europe/Madrid', label: 'Madrid - Spain' },
  { value: 'Asia/Magadan', label: 'Magadan - Russia' },
  { value: 'Indian/Mahe', label: 'Mahe - Seychelles' },
  { value: 'Pacific/Majuro', label: 'Majuro - Marshall Islands' },
  { value: 'Asia/Makassar', label: 'Makassar - Indonesia' },
  { value: 'Africa/Malabo', label: 'Malabo - Equatorial Guinea' },
  { value: 'Indian/Maldives', label: 'Maldives - Maldives' },
  { value: 'Europe/Malta', label: 'Malta - Malta' },
  { value: 'America/Managua', label: 'Managua - Nicaragua' },
  { value: 'America/Manaus', label: 'Manaus - Brazil' },
  { value: 'Asia/Manila', label: 'Manila - Philippines' },
  { value: 'Africa/Maputo', label: 'Maputo - Mozambique' },
  { value: 'Australia/Currie', label: 'Marengo - Australia' },
  { value: 'America/Indiana/Marengo', label: 'Marengo - United States' },
  { value: 'Europe/Mariehamn', label: 'Mariehamn - Aland Islands' },
  { value: 'America/Marigot', label: 'Marigot - Saint Martin' },
  { value: 'Pacific/Marquesas', label: 'Marquesas - French Polynesia' },
  { value: 'America/Martinique', label: 'Martinique - Martinique' },
  { value: 'Africa/Maseru', label: 'Maseru - Lesotho' },
  { value: 'America/Matamoros', label: 'Matamoros - Mexico' },
  { value: 'Indian/Mauritius', label: 'Mauritius - Mauritius' },
  { value: 'Antarctica/Mawson', label: 'Mawson - Antarctica' },
  { value: 'Indian/Mayotte', label: 'Mayotte - Mayotte' },
  { value: 'America/Mazatlan', label: 'Mazatlan - Mexico' },
  { value: 'Africa/Mbabane', label: 'Mbabane - Swaziland' },
  { value: 'Antarctica/McMurdo', label: 'McMurdo - Antarctica' },
  { value: 'America/Argentina/Mendoza', label: 'Mendoza - Argentina' },
  { value: 'America/Menominee', label: 'Menominee - United States' },
  { value: 'America/Merida', label: 'Merida - Mexico' },
  { value: 'America/Metlakatla', label: 'Metlakatla - United States' },
  { value: 'America/Mexico_City', label: 'Mexico City - Mexico' },
  { value: 'America/New_York', label: 'Miami - United States' },
  { value: 'Pacific/Midway', label: 'Midway - United States Minor Outlying Islands' },
  { value: 'Europe/Minsk', label: 'Minsk - Belarus' },
  { value: 'America/Miquelon', label: 'Miquelon - Saint Pierre and Miquelon' },
  { value: 'Africa/Mogadishu', label: 'Mogadishu - Somalia' },
  { value: 'Europe/Monaco', label: 'Monaco - Monaco' },
  { value: 'America/Moncton', label: 'Moncton - Canada' },
  { value: 'Africa/Monrovia', label: 'Monrovia - Liberia' },
  { value: 'America/Monterrey', label: 'Monterrey - Mexico' },
  { value: 'America/Montevideo', label: 'Montevideo - Uruguay' },
  { value: 'Europe/Vienna', label: 'Monticello - Austria' },
  { value: 'America/Kentucky/Monticello', label: 'Monticello - United States' },
  { value: 'America/Lato', label: 'Lato - Lato' },
  { value: 'Europe/Moscow', label: 'Moscow - Russia' },
  { value: 'Asia/Kolkata', label: 'Mumbai - India' },
  { value: 'Asia/Muscat', label: 'Muscat - Oman' },
  { value: 'Africa/Nairobi', label: 'Nairobi - Kenya' },
  { value: 'America/Nassau', label: 'Nassau - Bahamas' },
  { value: 'Pacific/Nauru', label: 'Nauru - Nauru' },
  { value: 'Africa/Ndjamena', label: 'Ndjamena - Chad' },
  { value: 'Asia/Kolkata', label: 'New Delhi - India' },
  { value: 'Australia/Adelaide', label: 'Adelaide - Australia' },
  { value: 'America/North_Dakota/New_Salem', label: 'New Salem - United States' },
  { value: 'America/New_York', label: 'New York - United States' },
  { value: 'Africa/Niamey', label: 'Niamey - Niger' },
  { value: 'Asia/Nicosia', label: 'Nicosia - Cyprus' },
  { value: 'America/Nipigon', label: 'Nipigon - Canada' },
  { value: 'Pacific/Niue', label: 'Niue - Niue' },
  { value: 'America/Nome', label: 'Nome - United States' },
  { value: 'Pacific/Norfolk', label: 'Norfolk - Norfolk Island' },
  { value: 'America/Noronha', label: 'Noronha - Brazil' },
  { value: 'Africa/Nouakchott', label: 'Nouakchott - Mauritania' },
  { value: 'Pacific/Noumea', label: 'Noumea - New Caledonia' },
  { value: 'Asia/Novokuznetsk', label: 'Novokuznetsk - Russia' },
  { value: 'Asia/Novosibirsk', label: 'Novosibirsk - Russia' },
  { value: 'America/Ojinaga', label: 'Ojinaga - Mexico' },
  { value: 'Asia/Omsk', label: 'Omsk - Russia' },
  { value: 'Asia/Oral', label: 'Oral - Kazakhstan' },
  { value: 'Europe/Oslo', label: 'Oslo - Norway' },
  { value: 'Africa/Ouagadougou', label: 'Ouagadougou - Burkina Faso' },
  { value: 'Pacific/Palau', label: 'Palau - Palau' },
  { value: 'Antarctica/Palmer', label: 'Palmer - Antarctica' },
  { value: 'America/Panama', label: 'Panama - Panama' },
  { value: 'America/Pangnirtung', label: 'Pangnirtung - Canada' },
  { value: 'America/Paramaribo', label: 'Paramaribo - Suriname' },
  { value: 'Europe/Paris', label: 'Paris - France' },
  { value: 'Australia/Perth', label: 'Perth - Australia' },
  { value: 'Australia/Melbourne', label: 'Petersburg - Australia' },
  { value: 'America/Indiana/Petersburg', label: 'Petersburg - United States' },
  { value: 'Asia/Phnom_Penh', label: 'Phnom Penh - Cambodia' },
  { value: 'America/Phoenix', label: 'Phoenix - United States' },
  { value: 'Pacific/Pitcairn', label: 'Pitcairn - Pitcairn' },
  { value: 'Europe/Podgorica', label: 'Podgorica - Montenegro' },
  { value: 'Pacific/Pohnpei', label: 'Pohnpei - Micronesia' },
  { value: 'Asia/Pontianak', label: 'Pontianak - Indonesia' },
  { value: 'Pacific/Port_Moresby', label: 'Port Moresby - Papua New Guinea' },
  { value: 'America/Port_of_Spain', label: 'Port of Spain - Trinidad and Tobago' },
  { value: 'America/Port-au-Prince', label: 'Port-au-Prince - Haiti' },
  { value: 'America/Porto_Velho', label: 'Porto Velho - Brazil' },
  { value: 'Africa/Porto-Novo', label: 'Porto-Novo - Benin' },
  { value: 'Europe/Prague', label: 'Prague - Czech Republic' },
  { value: 'America/Puerto_Rico', label: 'Puerto Rico - Puerto Rico' },
  { value: 'America/Punta_Arenas', label: 'Punta Arenas - Chile' },
  { value: 'Asia/Pyongyang', label: 'Pyongyang - North Korea' },
  { value: 'Asia/Qatar', label: 'Qatar - Qatar' },
  { value: 'Asia/Qyzylorda', label: 'Qyzylorda - Kazakhstan' },
  { value: 'America/Rainy_River', label: 'Rainy River - Canada' },
  { value: 'America/Rankin_Inlet', label: 'Rankin Inlet - Canada' },
  { value: 'Pacific/Rarotonga', label: 'Rarotonga - Cook Islands' },
  { value: 'America/Recife', label: 'Recife - Brazil' },
  { value: 'America/Regina', label: 'Regina - Canada' },
  { value: 'America/Resolute', label: 'Resolute - Canada' },
  { value: 'Indian/Reunion', label: 'Reunion - Reunion' },
  { value: 'Atlantic/Reykjavik', label: 'Reykjavik - Iceland' },
  { value: 'Europe/Riga', label: 'Riga - Latvia' },
  { value: 'America/Rio_Branco', label: 'Rio Branco - Brazil' },
  { value: 'America/Argentina/Rio_Gallegos', label: 'Rio Gallegos - Argentina' },
  { value: 'Asia/Riyadh', label: 'Riyadh - Saudi Arabia' },
  { value: 'Europe/Rome', label: 'Rome - Italy' },
  { value: 'Antarctica/Rothera', label: 'Rothera - Antarctica' },
  { value: 'Pacific/Saipan', label: 'Saipan - Northern Mariana Islands' },
  { value: 'Asia/Sakhalin', label: 'Sakhalin - Russia' },
  { value: 'America/Argentina/Salta', label: 'Salta - Argentina' },
  { value: 'Europe/Samara', label: 'Samara - Russia' },
  { value: 'Asia/Samarkand', label: 'Samarkand - Uzbekistan' },
  { value: 'America/Los_Angeles', label: 'San Francisco - United States' },
  { value: 'America/Argentina/San_Juan', label: 'San Juan - Argentina' },
  { value: 'America/Argentina/San_Luis', label: 'San Luis - Argentina' },
  { value: 'Europe/San_Marino', label: 'San Marino - San Marino' },
  { value: 'America/Santarem', label: 'Santarem - Brazil' },
  { value: 'America/Santiago', label: 'Santiago - Chile' },
  { value: 'America/Santo_Domingo', label: 'Santo Domingo - Dominican Republic' },
  { value: 'America/Sao_Paulo', label: 'Sao Paulo - Brazil' },
  { value: 'Africa/Sao_Tome', label: 'Sao Tome - Sao Tome and Principe' },
  { value: 'Europe/Sarajevo', label: 'Sarajevo - Bosnia and Herzegovina' },
  { value: 'Europe/Saratov', label: 'Saratov - Russia' },
  { value: 'America/Scoresbysund', label: 'Scoresbysund - Greenland' },
  { value: 'America/Los_Angeles', label: 'Seattle - United States' },
  { value: 'Asia/Seoul', label: 'Seoul - South Korea' },
  { value: 'Asia/Shanghai', label: 'Shanghai - China' },
  { value: 'Europe/Simferopol', label: 'Simferopol - Russia' },
  { value: 'Asia/Singapore', label: 'Singapore - Singapore' },
  { value: 'America/Sitka', label: 'Sitka - United States' },
  { value: 'Europe/Skopje', label: 'Skopje - Macedonia' },
  { value: 'Europe/Sofia', label: 'Sofia - Bulgaria' },
  { value: 'Atlantic/South_Georgia', label: 'South Georgia - South Georgia and the South Sandwich Islands' },
  { value: 'Asia/Srednekolymsk', label: 'Srednekolymsk - Russia' },
  { value: 'America/St_Barthelemy', label: 'St Barthelemy - Saint BarthÃ©lemy' },
  { value: 'Atlantic/St_Helena', label: 'St Helena - Saint Helena' },
  { value: 'America/St_Johns', label: 'St Johns - Canada' },
  { value: 'America/St_Kitts', label: 'St Kitts - Saint Kitts and Nevis' },
  { value: 'America/St_Lucia', label: 'St Lucia - Saint Lucia' },
  { value: 'America/St_Thomas', label: 'St Thomas - U.S. Virgin Islands' },
  { value: 'America/St_Vincent', label: 'St Vincent - Saint Vincent and the Grenadines' },
  { value: 'Atlantic/Stanley', label: 'Stanley - Falkland Islands' },
  { value: 'Europe/Stockholm', label: 'Stockholm - Sweden' },
  { value: 'America/Swift_Current', label: 'Swift Current - Canada' },
  { value: 'Antarctica/Syowa', label: 'Syowa - Antarctica' },
  { value: 'Pacific/Tahiti', label: 'Tahiti - French Polynesia' },
  { value: 'Asia/Taipei', label: 'Taipei - Taiwan' },
  { value: 'Europe/Tallinn', label: 'Tallinn - Estonia' },
  { value: 'Pacific/Tarawa', label: 'Tarawa - Kiribati' },
  { value: 'Asia/Tashkent', label: 'Tashkent - Uzbekistan' },
  { value: 'Asia/Tbilisi', label: 'Tbilisi - Georgia' },
  { value: 'America/Tegucigalpa', label: 'Tegucigalpa - Honduras' },
  { value: 'Asia/Tehran', label: 'Tehran - Iran' },
  { value: 'Australia/Broken_Hill', label: 'Tell City - Australia' },
  { value: 'America/Indiana/Tell_City', label: 'Tell City - United States' },
  { value: 'Asia/Thimphu', label: 'Thimphu - Bhutan' },
  { value: 'America/Thule', label: 'Thule - Greenland' },
  { value: 'America/Thunder_Bay', label: 'Thunder Bay - Canada' },
  { value: 'America/Tijuana', label: 'Tijuana - Mexico' },
  { value: 'Europe/Tirane', label: 'Tirane - Albania' },
  { value: 'Asia/Tokyo', label: 'Tokyo - Japan' },
  { value: 'Asia/Tomsk', label: 'Tomsk - Russia' },
  { value: 'Pacific/Tongatapu', label: 'Tongatapu - Tonga' },
  { value: 'America/Toronto', label: 'Toronto - Canada' },
  { value: 'America/Tortola', label: 'Tortola - British Virgin Islands' },
  { value: 'Africa/Tripoli', label: 'Tripoli - Libya' },
  { value: 'Antarctica/Troll', label: 'Troll - Antarctica' },
  { value: 'America/Argentina/Tucuman', label: 'Tucuman - Argentina' },
  { value: 'Africa/Tunis', label: 'Tunis - Tunisia' },
  { value: 'Asia/Ulaanbaatar', label: 'Ulaanbaatar - Mongolia' },
  { value: 'Europe/Ulyanovsk', label: 'Ulyanovsk - Russia' },
  { value: 'Asia/Urumqi', label: 'Urumqi - China' },
  { value: 'America/Argentina/Ushuaia', label: 'Ushuaia - Argentina' },
  { value: 'Asia/Ust-Nera', label: 'Ust-Nera - Russia' },
  { value: 'Europe/Uzhgorod', label: 'Uzhgorod - Ukraine' },
  { value: 'Europe/Vaduz', label: 'Vaduz - Liechtenstein' },
  { value: 'America/Vancouver', label: 'Vancouver - Canada' },
  { value: 'Europe/Vatican', label: 'Vatican - Vatican' },
  { value: 'Australia/Sydney', label: 'Sydney - Australia' },
  { value: 'Australia/Sydney', label: 'Melbourne - Australia' },
  { value: 'America/Indiana/Vevay', label: 'Vevay - United States' },
  { value: 'Asia/Vientiane', label: 'Vientiane - Laos' },
  { value: 'Europe/Vilnius', label: 'Vilnius - Lithuania' },
  { value: 'Antarctica/Macquarie', label: 'Vincennes - Australia' },
  { value: 'America/Indiana/Vincennes', label: 'Vincennes - United States' },
  { value: 'Asia/Vladivostok', label: 'Vladivostok - Russia' },
  { value: 'Europe/Volgograd', label: 'Volgograd - Russia' },
  { value: 'Antarctica/Vostok', label: 'Vostok - Antarctica' },
  { value: 'Pacific/Wake', label: 'Wake - United States Minor Outlying Islands' },
  { value: 'Pacific/Wallis', label: 'Wallis - Wallis and Futuna' },
  { value: 'Europe/Warsaw', label: 'Warsaw - Poland' },
  { value: 'America/Whitehorse', label: 'Whitehorse - Canada' },
  { value: 'Australia/Hobart', label: 'Winamac - Australia' },
  { value: 'America/Indiana/Winamac', label: 'Winamac - United States' },
  { value: 'Africa/Windhoek', label: 'Windhoek - Namibia' },
  { value: 'America/Winnipeg', label: 'Winnipeg - Canada' },
  { value: 'America/Yakutat', label: 'Yakutat - United States' },
  { value: 'Asia/Yakutsk', label: 'Yakutsk - Russia' },
  { value: 'Asia/Yangon', label: 'Yangon - Myanmar' },
  { value: 'Asia/Yekaterinburg', label: 'Yekaterinburg - Russia' },
  { value: 'America/Yellowknife', label: 'Yellowknife - Canada' },
  { value: 'Asia/Yerevan', label: 'Yerevan - Armenia' },
  { value: 'Europe/Zagreb', label: 'Zagreb - Croatia' },
  { value: 'Europe/Zaporozhye', label: 'Zaporozhye - Ukraine' },
  { value: 'Europe/Zurich', label: 'Zurich - Switzerland' },
];
