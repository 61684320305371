import React, { Component } from 'react'
import Header from '../../common/header/header'
import Footer from '../../common/footer/footer'
import { connect } from 'react-redux';
import { getCurrentUser, isAuthenticated } from '../../actions/authActions';
class Page3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const {
            state: {
            },
            props: {
                user,
            }
        } = this;
        return (
            <div>
                <Header />

                <main>
                    <section className="block-table tab-wrap">
                        <div className="table-wrap table-wrap__w830">
                            <div className="table-info">
                                <a href="#" className="table-game">
                                    <img src={user && user.imageUrl ? user.imageUrl : "images/games/game-1.jpg"} alt="" />
                                </a>
                                <h2>GAME NAME</h2>
                                <p>TOURNAMENT END DATE: 3 MAR ‘21</p>
                            </div>
                            <div className="table winner-table">
                                <div className="t-head">
                                    <div className="t-user">Username</div>
                                    <div className="t-prize">Position</div>
                                    <div className="t-cash">Cash Prize</div>
                                </div>
                                <div className="t-row my-position">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-9.jpg" alt="" />
                                            You
                                        </a>
                                    </div>
                                    <div className="t-prize">//</div>
                                    <div className="t-cash">
                                        <div className="my-cash">//</div>
                                    </div>
                                </div>
                                <div className="t-row my-position">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-9.jpg" alt="" />
                                            You
                                        </a>
                                    </div>
                                    <div className="t-prize">96°</div>
                                    <div className="t-cash">
                                        <div className="my-cash">40 <img src="images/icons/coin-icon.png" alt="" /></div>
                                    </div>
                                </div>
                                <div className="t-row my-position">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-9.jpg" alt="" />
                                            You
                                        </a>
                                    </div>
                                    <div className="t-prize">16°</div>
                                    <div className="t-cash">
                                        <div className="my-cash">$4 <img src="images/icons/cash-icon.png" alt="" /></div>
                                    </div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-4.jpg" alt="" />
                                            Pablo
                                        </a>
                                    </div>
                                    <div className="t-prize">1°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-5.jpg" alt="" />
                                            Claudio
                                        </a>
                                    </div>
                                    <div className="t-prize">2°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-6.jpg" alt="" />
                                            Merith
                                        </a>
                                    </div>
                                    <div className="t-prize">3°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-7.jpg" alt="" />
                                            Jekis
                                        </a>
                                    </div>
                                    <div className="t-prize">4°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-8.jpg" alt="" />
                                            Mark
                                        </a>
                                    </div>
                                    <div className="t-prize">5°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-4.jpg" alt="" />
                                            Pablo
                                        </a>
                                    </div>
                                    <div className="t-prize">1°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-5.jpg" alt="" />
                                            Claudio
                                        </a>
                                    </div>
                                    <div className="t-prize">2°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-6.jpg" alt="" />
                                            Merith
                                        </a>
                                    </div>
                                    <div className="t-prize">3°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-7.jpg" alt="" />
                                            Jekis
                                        </a>
                                    </div>
                                    <div className="t-prize">4°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                                <div className="t-row">
                                    <div className="t-user">
                                        <a className="table-user" href="#">
                                            <img src="images/photos/photo-8.jpg" alt="" />
                                            Mark
                                        </a>
                                    </div>
                                    <div className="t-prize">5°</div>
                                    <div className="t-cash color-green">$750</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth ? state.auth.user : null,
});

export default connect(mapStateToProps,)(Page3);