import React, { Component } from 'react'
import Signup from './signup'
export default class Register extends Component {
  render() {
    return (
      <div>
        <Signup />
        {/* <!--popup via soc--> */}
        <div id="register-soc" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">PLAY GAMES AND EARN CASH</h2>
              <p className="register-text">REGISTER VIA:</p>
              <div className="register-soc">
                <a className="register-mail" id="register-mail" data-fancybox="#register-form" data-src="#register-form" href="#"><img src="images/icons/mail-icon.png" alt="" /></a>
                <a className="register-facebook" href="#"><img src="images/icons/facebook-icon.png" alt="" /></a>
                <a className="register-google" href="#"><img src="images/icons/google-icon.png" alt="" /></a>
              </div>
              <p className="register-log">Already registered?</p>
              <a href="#" className="btn btn__icon--r btn__blue btn-login" data-fancybox="#login" data-src="#login">LOG IN <i className="icon-lock"></i></a>
              <p className="popup-bottom__link"><a href="#">By registering you accept the Contest Rules and Privacy Policy</a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

