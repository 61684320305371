import React, { Component } from 'react'
import Header from '../../common/header/header'

export default class Game2 extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="game-header">
    <button className="game-btn game-btn__pause">
        <i className="icon-pause"></i>
    </button>
    <p className="game-score text-center">SCORE: <span>128pt</span></p>
    <div className="game-btn game-btn__volume">
        <i className="icon-volume"></i>
    </div>
</div>
<div className="game-content">
    <img src="images/other/game.jpg" alt=""/>
</div>
            </div>
        )
    }
}
