import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from '../../common/header/header'
import Footer from '../../common/footer/footer'
import { sweepStake, getGames, getTournaments } from '../../actions/sweepStake'
import { tournaments } from '../../actions/tournaments'
// import moment from "moment"
import moment from 'moment-timezone';
// import 'moment-countdown' from 'moment-countdown'
import $ from "jquery"
import elem from "jquery"
import dateHelper from '../../helpers/dateHelper'

import jstz from 'jstimezonedetect';
// import { getCurrentUser, isAuthenticated } from '../../actions/authActions';
const tz = jstz.determine().name();
const TIMEZONE = dateHelper.getTimeZone(tz);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDate: "",
    };

  }

  componentDidMount() {
    this.props.sweepStakeDispatch()
    this.props.fetchGamesDispatch()
    this.props.fetchTournamentsDispatch()
    // if (localStorage.jwtToken) {
    //   this.props.isAuthenticatedDispatch()
    //   this.props.fetchCurrentUserDispatch()
    // }



    const date = new Date()
    const day = date.getDay()
    let remainigDaysTournament = 0

    if (day == 0) {
      remainigDaysTournament = 0
    } else {
      remainigDaysTournament = 7 - day
    }


    console.log('remainig time : ' + remainigDaysTournament)

    const hours = new Date().getHours();
    const remainigHoursTournament = 24 - hours - 1
    console.log('remainig hours in day : ' + remainigHoursTournament)

    const mins = new Date().getMinutes()
    const remainigMinssTournament = 60 - mins
    console.log('remainig mins : ' + remainigMinssTournament)

    const elem = "#timer-tournaments1"
    if ($(elem).length != 0) {
      console.log('INNN', remainigDaysTournament, remainigHoursTournament, remainigMinssTournament)
      setInterval(function () {
        var h = hours / 10;
        // var days = Math.floor(result / 1000 / 60 / 60 / 24);
        var d1 = remainigDaysTournament / 10;
        var h1 = remainigHoursTournament / 10;
        var m1 = remainigMinssTournament / 10;

        $(elem).find('.days').html('<span>' + Math.trunc(d1) + '</span><span>' + remainigDaysTournament % 10 + '</span>');
        $(elem).find('.hours').html('<span>' + Math.trunc(h1) + '</span><span>' + remainigHoursTournament % 10 + '</span>');
        $(elem).find('.minutes').html('<span>' + Math.trunc(m1) + '</span><span>' + remainigMinssTournament % 10 + '</span>');
      }, 3000);
    }
  }



  handleAllWinners = (e) => {
    e.preventDefault();
    window.location.href = 'winner';
  }


  getGameIcon = (tournamentObj) => {
    const {
      props: {
        games,
      },
    } = this;
    let resp = ''
    if (games && games.length > 0) {
      const gameName = this.getGameName(tournamentObj);
      // const icon = games.find (obj => obj.name == gameName);
      // resp = icon.icons[0].imageURL;
      resp = this.getGameImg(gameName);
    }
    return resp;
  }

  getGameImg = (gameName) => {
    const {
      props: {
        games,
      },
    } = this;
    if (!gameName) {
      return 'images/games/game-2.jpg'
    }
    const gameObj = games.find(obj => obj.name == gameName);
    return gameObj.icons[0].imageURL;
  }

  getGameName = (tournamentObj) => {
    const {
      props: {
        games,
      },
    } = this;
    const gameName = tournamentObj.gameName || tournamentObj.game[0].name
    return gameName;
  }

  getTournamentStartTimeText = (tournamentObj) => {
    const current = moment().tz(TIMEZONE.value);

    const end = tournamentObj.startDate;
    var duration = moment.duration(current.diff(end));
    var days = duration.asDays();
    var hours = duration.hours();

    var mins = duration.minutes();
    var secs = duration.seconds();
    let resp = '';

    const s2 = current.diff(end, 'seconds')

    days = parseInt(Math.abs(days))
    mins = parseInt(Math.abs(mins))
    hours = parseInt(Math.abs(hours))
    secs = parseInt(Math.abs(secs))


    if (days > 0) {
      resp = `${days} d`
    }
    if (hours > 0) {
      resp = `${resp} ${hours} h`
    }
    if (mins > 0) {
      resp = `${resp} ${mins} m`
    }
    return resp;
  }

  getTime = (arrayType) => {
    let startTime = arrayType && arrayType.map((date) => {
      return date.finishDate
    });

    const now = moment().tz(TIMEZONE.value);
    startTime = startTime.filter(obj => moment(obj).tz(TIMEZONE.value) > now)
    const sortedtime = startTime.sort((a, b) => {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA - dateB
    });
    return moment(sortedtime[0]).format("YYYY-MM-DD");
  }

  filterNextTournament = (tournaments) => {
    const now = moment().tz(TIMEZONE.value);
    let nextTournateDate = moment().tz(TIMEZONE.value).add(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD")
    const newArr = tournaments.filter(function (obj) {
      if ((moment(obj.startDate).tz(TIMEZONE.value) > now) && !moment(obj.startDate).tz(TIMEZONE.value).isSame(new Date(), 'week')) {
        return true;
      }
    });

    if (newArr.length > 0) {
      nextTournateDate = moment(newArr[0].startDate).format("YYYY-MM-DD")
    }
    return nextTournateDate;
  }

  getTournamentActiveClass = (tournament) => {
    const now = moment().tz(TIMEZONE.value);

    let tournamentClassName = "tournament-img";
    if ((moment(tournament.startDate).tz(TIMEZONE.value) > now)) {
      tournamentClassName = "tournament-img tournament-img__active";
    }
    return tournamentClassName;
  }

  getTournamentTime = () => {
    const dayINeed = 7; // for Thursday
    const today = moment().tz(TIMEZONE.value).isoWeekday();
    let resp = null;
    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed) {
      // then just give me this week's instance of that day
      resp = moment().tz(TIMEZONE.value).isoWeekday(dayINeed).endOf('day');
    } else {
      // otherwise, give me *next week's* instance of that same day
      resp = moment().tz(TIMEZONE.value).add(1, 'weeks').isoWeekday(dayINeed).endOf('day');
    }
    resp = resp.format("YYYY-MM-DD");
    return resp;
  }

  getWeeklyTournaments = () => {
    const {
      props: {
        tournaments,
      }
    } = this
    const current = moment().tz(TIMEZONE.value);
    const newArr = tournaments.filter(function (obj) {
      if (moment(obj.startDate).tz(TIMEZONE.value).isSame(current, 'week')) {
        return true;
      }
    });
    return newArr;
  }

  getArr1 = () => {
    const {
      props: {
        tournaments,
      }
    } = this
    const newArr = tournaments.filter(function (tournamentObj) {
      if (moment(tournamentObj.startDate).tz(TIMEZONE.value).isBefore() && moment(tournamentObj.finishDate).tz(TIMEZONE.value).isAfter()) {
        return true;
      }
    });
    return newArr
  }

  getArr2 = () => {
    const {
      props: {
        tournaments,
      }
    } = this
    const newArr = tournaments.filter(function (tournamentObj) {
      if (moment(tournamentObj.startDate).tz(TIMEZONE.value).isAfter()) {
        return true;
      }
    });
    return newArr
  }

  getTournamentEndTimeText = (tournament) => {
    const current = moment().tz(TIMEZONE.value);

    const end = tournament.finishDate;
    var duration = moment.duration(current.diff(end));

    var days = duration.asDays();
    var hours = duration.asHours();

    var mins = duration.minutes();
    var secs = duration.seconds();
    let resp = '';

    days = parseInt(Math.abs(days))
    mins = parseInt(Math.abs(mins))
    hours = parseInt(Math.abs(hours))
    secs = parseInt(Math.abs(secs))


    if (days > 0) {
      resp = `${days} d `
    }
    if (hours > 0) {
      resp = `${resp}${hours} h `
    }
    if (mins > 0) {
      resp = `${resp}${mins} m `
    }

    resp = `${resp}left`
    return resp;
  }

  getPercentageBar = (tournament) => {
    const current = moment().tz(TIMEZONE.value);

    const end = tournament.finishDate;

    const seconds = Math.abs(current.diff(end, 'seconds'))
    let s1 = Math.abs(100 - Math.round(seconds / 3600))
    if (s1 > 100) {
      s1 = 100
    }
    s1 = `${s1}%`
    return s1;
  }

  getGamePath = (tournament) => {
    let name = this.getGameName(tournament)
    name = name.replace(/ /g, "_").toLocaleLowerCase()
    return `gameplay?gamename=${name}`;
  }

  getGameUrl = (gameObj) => {
    // filteredGames
    let resp = '#';
    if (gameObj) {
      let name = gameObj.name.replace(/ /g, "_").toLocaleLowerCase()
      resp = `gameplay?gamename=${name}`
    }
    return resp;
  }

  render() {

    const {
      props: {
        sweepstake,
        games,
        tournaments,
      }
    } = this;
    const self = this;
    var count = 0
    const filteredGames = games.filter(function(item) {
      if (count < 5 && item) {
        count++;
        return true;
      }
      return false;
    }, {count: 0});


    let sum = 0;
    let tournamentSum = 0;
    let sortedSweepTime = moment.tz().format("YYYY-MM-DD")
    let sortedTournamentTime = this.getTournamentTime();
    let tournamentsArray = []

    if (sweepstake && sweepstake.length > 0) {
      sortedSweepTime = this.getTime(sweepstake);
    }

    if (tournaments && tournaments.length > 0) {
      tournamentsArray = this.getWeeklyTournaments(tournaments);
      const t1 = this.getArr1();
      const t2 = this.getArr2();
      tournamentsArray = t1.concat(t2)
      // sortedTournamentTime = this.filterNextTournament(tournaments)

      const tournament2 = this.getWeeklyTournaments();
      tournamentSum = tournament2.reduce(function (prev, cur) {
        return prev + cur.totalJackpotAmount;
      }, 0);
    }

    if (sweepstake && sweepstake.length > 0) {
      sum = sweepstake.reduce(function (prev, cur) {
        return prev + cur.totalJackpotAmount;
      }, 0);
    }

    return (

      <div>
        <Header />

        <main>


          <section className="block-banner tab-wrap">
            <div className="container">
              <div className="tab-content tab-content__banner">
                <div className="row align-item-center">
                  <div className="col-lg-6 wow animate__animated animate__fadeInLeft">
                    <div className="banner-l">
                      <div className="banner-text">
                        <h3><i>Sweepstake</i></h3>
                        <h2>CASH PRIZE POOL <i>$</i> <span>{sum}</span></h2>
                      </div>
                      <div className="banner-time">
                        <h4>Next Draw in</h4>
                        <div id="timer-sweepstake" className="timer" data-date={sortedSweepTime ? sortedSweepTime : ""}>
                          <div className="timer-box">
                            <div className="timer-num days"><span>0</span><span>0</span></div>
                            <p>Days</p>
                          </div>
                          <div className="timer-box">
                            <div className="timer-num hours"><span>0</span><span>0</span></div>
                            <p>Hours</p>
                          </div>
                          <div className="timer-box">
                            <div className="timer-num minutes"><span>0</span><span>0</span></div>
                            <p>Minutes</p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner-winners">
                      <div className="winner-img winner-img__1 active wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="-1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-12.png" alt="" data-speed="-0.4" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-11.png" alt="" data-speed="-0.2" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-10.png" alt="" data-speed="-0.05" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="banner-winners__r">
                        <div className="top-users">
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".2">
                            <div className="user-photo">
                              <img src="images/photos/photo-1.jpg" alt="" />
                            </div>
                            <div className="user-info">
                              <h4>Mi name</h4>
                              <p>$5670</p>
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".4">
                            <div className="user-photo">
                              <img src="images/photos/photo-2.jpg" alt="" />
                            </div>
                            <div className="user-info">
                              <h4>Mi name</h4>
                              <p>$5670</p>
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".6">
                            <div className="user-photo">
                              <img src="images/photos/photo-3.jpg" alt="" />
                            </div>
                            <div className="user-info">
                              <h4>Mi name</h4>
                              <p>$5670</p>
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".8">
                            <div className="user-photo">
                              <img src="images/photos/photo-2.jpg" alt="" />
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration="1">
                            <div className="user-photo">
                              <img src="images/photos/photo-1.jpg" alt="" />
                            </div>
                          </div>
                        </div>
                        <a href="#" onClick={(e) => this.handleAllWinners(e)} className="all-users wow animate__animated animate__fadeInRight" data-wow-duration="1.2">All</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content tab-content__banner">
                <div className="row align-item-center">
                  <div className="col-lg-6  wow animate__animated animate__fadeInLeft">
                    <div className="banner-l">
                      <div className="banner-text">
                        <h3><i>Tournaments</i></h3>
                        <h2>JACKPOT <i>$</i> <span>{tournamentSum}</span></h2>
                      </div>
                      <div className="banner-time">
                        <h4>Next Jackpot in</h4>
                        <div id="timer-tournaments1" className="timer" data-date={sortedTournamentTime ? sortedTournamentTime : ""}>
                          <div className="timer-box">
                            <div className="timer-num days"></div>
                            <p>Days</p>
                          </div>
                          <div className="timer-box">
                            <div className="timer-num hours"></div>
                            <p>Hours</p>
                          </div>
                          <div className="timer-box">
                            <div className="timer-num minutes"></div>
                            <p>Minutes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner-winners">
                      <div className="winner-img winner-img__2 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-1.png" alt="" className="parallax-icon" data-speed="-0.3" />
                        <img src="images/banner/paralax-icon-2.png" alt="" className="parallax-icon" data-speed="-0.2" />
                        <img src="images/banner/paralax-icon-3.png" alt="" className="parallax-icon" data-speed="-0.4" />
                        <img src="images/banner/paralax-icon-4.png" alt="" className="parallax-icon" data-speed="-0.6" />
                        <img src="images/banner/paralax-icon-5.png" alt="" className="parallax-icon" data-speed="-0.26" />
                        <img src="images/banner/paralax-icon-9.png" alt="" className="parallax-icon" data-speed="-0.05" />
                        <img src="images/banner/banner-img-4.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__1 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__1 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__2 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-1.png" alt="" className="parallax-icon" data-speed="-0.3" />
                        <img src="images/banner/paralax-icon-2.png" alt="" className="parallax-icon" data-speed="-0.2" />
                        <img src="images/banner/paralax-icon-3.png" alt="" className="parallax-icon" data-speed="-0.4" />
                        <img src="images/banner/paralax-icon-4.png" alt="" className="parallax-icon" data-speed="-0.6" />
                        <img src="images/banner/paralax-icon-5.png" alt="" className="parallax-icon" data-speed="-0.26" />
                        <img src="images/banner/paralax-icon-9.png" alt="" className="parallax-icon" data-speed="-0.05" />
                        <img src="images/banner/banner-img-4.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__2 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-1.png" alt="" className="parallax-icon" data-speed="-0.3" />
                        <img src="images/banner/paralax-icon-2.png" alt="" className="parallax-icon" data-speed="-0.2" />
                        <img src="images/banner/paralax-icon-3.png" alt="" className="parallax-icon" data-speed="-0.4" />
                        <img src="images/banner/paralax-icon-4.png" alt="" className="parallax-icon" data-speed="-0.6" />
                        <img src="images/banner/paralax-icon-5.png" alt="" className="parallax-icon" data-speed="-0.26" />
                        <img src="images/banner/paralax-icon-9.png" alt="" className="parallax-icon" data-speed="-0.05" />
                        <img src="images/banner/banner-img-4.png" alt="" />
                      </div>
                      <div className="banner-winners__r">
                        <div className="top-users 23">
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".2">
                            <div className="user-photo">
                              <img src="images/photos/photo-1.jpg" alt="" />
                            </div>
                            <div className="user-info">
                              <h4>Mi name</h4>
                              <p>$5670</p>
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".4">
                            <div className="user-photo">
                              <img src="images/photos/photo-2.jpg" alt="" />
                            </div>
                            <div className="user-info">
                              <h4>Mi name</h4>
                              <p>$5670</p>
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".6">
                            <div className="user-photo">
                              <img src="images/photos/photo-3.jpg" alt="" />
                            </div>
                            <div className="user-info">
                              <h4>Mi name</h4>
                              <p>$5670</p>
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration=".8">
                            <div className="user-photo">
                              <img src="images/photos/photo-2.jpg" alt="" />
                            </div>
                          </div>
                          <div className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration="1">
                            <div className="user-photo">
                              <img src="images/photos/photo-1.jpg" alt="" />
                            </div>
                          </div>
                        </div>
                        <a onClick={(e) => this.handleAllWinners(e)} href="#" className="all-users wow animate__animated animate__fadeInRight" data-wow-duration="1.2">All</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="tab-menu banner-menu">
                <li className="active"><span>Sweepstake</span></li>
                <li><span>Tournaments</span></li>
              </ul>
            </div>
          </section>
          <section className="block-feature">
            <div className="container">
              <div className="block-title block-title__violet">
                <div className="block-title__l">
                  <h2>featured games</h2>
                  <p>GET LUCKY COINS TO WIN THE SWEEPSTAKE <span>CASH PRIZE</span></p>
                </div>
                <div className="block-title__r">
                  <div className="block-title__r--top"><h3><span className="line-animation">Earn more</span></h3> <img className="coin" src="images/icons/coin-icon.png" alt="" /></div>
                  <p>SCORE POINTS TO GET LUCKY COINS</p>
                </div>
              </div>

              <div className="feature-game__slider">

              {filteredGames && filteredGames.map((element, index) => {
                var dot = '.'
                var ind = index
                var wow_delay = dot + (ind += 2)
                return (
                  
                  <div key={index} className="feature-game__col wow animate__animated animate__fadeInUp" data-wow-delay={wow_delay}>
                    <div className="feature-game">
                      <span className="feature-game__num">{++index}</span>
                      <a href={this.getGameUrl(filteredGames.length > 0 ? element : null)} className="feature-game__img">
                        <img src={filteredGames && filteredGames.length > 0 ? this.getGameImg(element.name) : 'images/games/game-1.jpg'} alt="" />
                      </a>
                      <h3 className="feature-game__name"><a href="#">{filteredGames && filteredGames.length > 0 ? element.name : 'Test'}</a></h3>
                    </div>
                  </div>
                  
                
                )

              })

              }


                


              </div>
            </div>
          </section>
          <section className="block-tournaments" id="tournament-scroll">
            <div className="container">
              <div className="block-title block-title__green">
                <div className="block-title__l">
                  <h2>Tournaments</h2>
                  <p>Score the most points to win <span>cash</span></p>
                </div>
                <div className="block-title__r">
                  <div className="block-title__r--top">
                    <h3>Jackpot <i>$</i> <span>{tournamentSum}</span></h3>
                    <img className="money" src="images/icons/money-icon.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="row">
                {tournamentsArray && tournamentsArray.map((tournamentObj, index1) => {
                  const now = moment().tz(TIMEZONE.value);

                  if (moment(tournamentObj.startDate).tz(TIMEZONE.value).isBefore() && moment(tournamentObj.finishDate).tz(TIMEZONE.value).isAfter()) {
                    const percentageBar = self.getPercentageBar(tournamentObj)
                    return (
                      <div key={index1} className="col-lg-3 col-md-4 col-sm-6 mb60">
                        <div className="tournament wow animate__animated animate__slideInUp" data-wow-delay=".1s">
                          <div className="tournament-jackpot">
                            <h3>$ <span>{tournamentObj.totalJackpotAmount}</span></h3>
                            <p>Jackpot</p>
                            <img src="images/icons/money-icon.png" alt="" />
                          </div>
                          <div className={this.getTournamentActiveClass(tournamentObj)}>
                            <img src={self.getGameIcon(tournamentObj)} alt="" />
                          </div>
                          <div className="tournament-progress">
                            {/*<div className="progress-line" data-hours="2 Hours left" style={{width: "60%"}}></div>*/}
                            <div className="progress-line" data-hours={self.getTournamentEndTimeText(tournamentObj)} style={{ width: percentageBar }}></div>

                          </div>
                          <h3 className="tournament-title tournament-title__uppercase">{self.getGameName(tournamentObj)}</h3>
                          <p className="tournament-position">Your position: <span>//</span></p>
                          <a href={this.getGamePath(tournamentObj)} className="btn btn__blue btn__icon--r"><span>PLAY</span> <i className="icon-play"></i></a>
                        </div>
                      </div>
                    )
                  } else if (moment(tournamentObj.startDate).tz(TIMEZONE.value).isAfter()) {
                    return (
                      <div key={index1} className="col-lg-3 col-md-4 col-sm-6 mb60">
                        <div className="tournament wow animate__animated animate__slideInUp" data-wow-delay=".6s">
                          <div className="tournament-img tournament-img__active">
                            <img src={self.getGameIcon(tournamentObj)} alt="" />
                          </div>
                          {/*<div className="tournament-time">Starts in <span>3h 15m 49s</span></div>*/}
                          <div className="tournament-time">Starts in <span>{self.getTournamentStartTimeText(tournamentObj)}</span></div>

                          <h3 className="tournament-title tournament-title__standard">{self.getGameName(tournamentObj)}</h3>
                          <p className="tournament-position">Your position: <span>//</span></p>
                          <a href="#" className="btn btn__gray btn__icon--r"><span>Get Notified</span> <i className="icon-bell-o"></i></a>
                        </div>
                      </div>
                    )
                  }
                })}

              </div>

            </div>
          </section>

          <section className="block-games">
            <div className="container">
              <div className="block-title block-title__violet">
                <div className="block-title__l">
                  <h2>All Games</h2>
                  <p>GET LUCKY COINS TO WIN THE SWEEPSTAKE <span>CASH PRIZE</span></p>
                </div>
                <div className="block-title__r">
                  <div className="block-title__r--top"><h3><span className="line-animation">Earn more</span></h3> <img className="coin" src="images/icons/coin-icon.png" alt="" /></div>
                  <p>SCORE POINTS TO GET LUCKY COINS</p>
                </div>
              </div>
              <div className="row">
                {games && games.map((gameObj, index) => (
                  <div key={index} className="col-lg-2 col-md-3 col-sm-4 col-xs-6 mb50">
                    <div className="game wow animate__animated animate__slideInUp" data-wow-delay=".2s">
                      <a href={this.getGameUrl(gameObj)} className="game-img">
                        <img src={gameObj.icons[0].imageURL} alt="" />
                      </a>
                      <h3 className="game-name"><a href="#">{gameObj.name}</a></h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="block-download">
            <div className="container">
              <div className="download-text">
                <h2 className="wow animate__animated animate__fadeInLeft" data-wow-delay="0.5s">download<br /> the <span>app</span></h2>
                <h3 className="wow animate__animated animate__fadeInLeft" data-wow-delay="1s">Play everywhere at anytime</h3>
                <div className="download-icon wow animate__animated animate__zoomIn" data-wow-delay="1s">
                  <a className="download-logo" href="#"><img src="images/other/logo-download.png" alt="" /></a>
                  <a className="download-code" href="#"><img src="images/other/qr-code.png" alt="" /></a>
                </div>
                <div className="download-btn wow animate__animated animate__backInUp" data-wow-delay="1.5s">
                  <a className="btn" href="#"><i className="icon-apple"></i> App Store</a>
                  <a className="btn" href="#"><i className="icon-googleplay"></i> Google Play</a>
                </div>
              </div>
              <div className="download-img wow animate__animated animate__zoomIn" data-wow-delay="1s">
                <img src="images/other/download-img.png" alt="" />
              </div>
            </div>
          </section>
          <section className="block-advantage">
            <div className="container">
              <div className="advantage-row">
                <div className="advantage-img wow animate__animated animate__swing">
                  <img src="images/other/advantage-img.png" alt="" />
                </div>
                <div className="advantage-text">
                  <h2 className="wow animate__animated animate__slideInDown">are you a game <span>developer?</span><br /> Earn with playtoo!</h2>
                  <a className="btn btn__gradient wow animate__animated animate__slideInUp" href="#">apply now!</a>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />

      </div>
    )

  }
}

const mapDispatchToProps = dispatch => ({
  sweepStakeDispatch: (data) => {
    dispatch(sweepStake(data));
  },
  fetchGamesDispatch: () => {
    dispatch(getGames());
  },
  fetchTournamentsDispatch: () => {
    dispatch(getTournaments());
  },
  // fetchCurrentUserDispatch: () => {
  //   dispatch(getCurrentUser());
  // },
  // isAuthenticatedDispatch: () => {
  //   dispatch(isAuthenticated(true));
  // }
});

const mapStateToProps = state => ({
  sweepstake: state.sweepstake.sweepstakes ? state.sweepstake.sweepstakes.data.sweepstakes : [],
  games: state.sweepstake.games ? state.sweepstake.games : [],
  tournaments: state.sweepstake.tournaments ? state.sweepstake.tournaments : [],
  user: state.auth ? state.auth.user : null,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
