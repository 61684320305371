import React, { Component } from "react";
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPassword } from '../../actions/authActions'
import $ from 'jquery';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      message: '',
      emailValid: false,
      passwordValid: false,
    };
  }

  componentDidMount() {
    $('.changepass-form').change(this.onChange);

    $('#changepass-submit').click(this.onSubmit);
  
  }




  onSubmit = e => {
    e.preventDefault();

    const data = {
      email: this.state.email,
      password: this.state.password
    };

    console.log(data, "data")
    this.props.forgotPassword(data, this.props.history);
  }

  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  validateField(fieldName, value) {
    let emailmatch = '';
    let emailValid = this.state.emailValid;
    let passwordlength = '';
    let passwordValid = this.state.passwordValid;
    switch (fieldName) {
      case 'email':
        emailmatch = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailValid = emailmatch ? '' : 'Email is invalid';
        break;
      case 'password':
        passwordlength = value.length >= 6;
        passwordValid = passwordlength ? '' : ' Password is too short. must be 6 characters long';
        break;
      default:
        break;
    }
    this.setState({
      emailValid,
      passwordValid,
    });
  }

  render() {
    // const { loading } = this.props.profile;
    // if (loading) {
    //   return <Spinner />;
    // }
    return (
      <div>
      

        {/* <!--popup change password--> */}
        <div id="change-password" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">CHANGE PASSWORD</h2>
              <div className="style-form register-form">
                <form action="">
                  <div className="field field-icon">
                  <i className="icon-mail-envelope-closed"></i>
                    <input type="email" name="email" className="changepass-form" placeholder="Enter email" value={this.state.email} onChange={this.onChange} required="required"/>
                    {this.state.emailValid ? <span style={{color: "red"}}>{this.state.emailValid}</span>: ""}
                    {this.props.error.email ? <span style={{color: "red"}}>{this.props.error.email }</span>: ""}
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" name="password" className="changepass-form" placeholder="New password" value={this.state.password} onChange={this.onChange} required="required"/>
                    {this.props.error.password ? <span style={{color: "red"}}>{this.props.error.password }</span>: ""}
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <button className="btn btn__blue btn__icon--r" id="changepass-submit" type="submit">
                    confirm
                        <i className="icon-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
ChangePassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  error: state.error
});

export default connect(mapStateToProps, { forgotPassword })(withRouter(ChangePassword));