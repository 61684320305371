import React, { Component } from "react";
import PropTypes from 'prop-types';
import AlertMessage from '../../common/AlertMessage';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../common/header/header';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';
import { loginJwt } from '../../actions/authActions'
import { loginWithFacebook, loginWithGoogle } from '../../actions/authActions';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Forgetpass from './forgotPassword'
import $ from 'jquery';


class Login extends Component {
  constructor(props) {
    super(props);
    this.fbRef =React.createRef()
    this.state = {
      email: '',
      password: '',
      message: '',
      emailValid: false,
      passwordValid: false,

    };
  }
  componentDidMount() {

    $('#login-submit').click(this.onSubmit);

    $('.login-form').change(this.onChange);

    //  $('#fblogin').click(this.GoogleLog)

    // this.fbRef.current.focus()
      

     $('#fb-login').click(this.clickHandler)
  }

  clickHandler = (e)=>{
    e.preventDefault()
   // alert(this.fbRef.current.value)
   console.log("Facebook refrence working")
   console.log("refrence",this.fbRef)
   //alert("Helo")

  }

  responseFacebook = (response) => {
    const data = {
      name: response.name,
      email: response.email,
      facebookId: response.id
    };

    this.props.loginWithFacebook(data, this.props.history);

  }

  responseGoogle = (response) => {
    const data = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      googleId: response.googleId,
    };

    this.props.loginWithGoogle(data, this.props.history);
  }



  onSubmit = e => {
    e.preventDefault();
    const data = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginJwt(data, this.props.history);
  }

  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  validateField(fieldName, value) {
    let emailmatch = '';
    let emailValid = this.state.emailValid;
    let passwordlength = '';
    let passwordValid = this.state.passwordValid;
    switch (fieldName) {
      case 'email':
        emailmatch = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailValid = emailmatch ? '' : 'Email is invalid';
        break;
      case 'password':
        passwordlength = value.length >= 6;
        passwordValid = passwordlength ? '' : ' Password is too short. must be 6 characters long';
        break;
      default:
        break;
    }
    this.setState({
      emailValid,
      passwordValid,
    });
  }


  // GoogleLog = (e) =>{
  //   console.log("hakunaMatata")
  //   e.preventDefault();
  //   return (
  //   <GoogleLogin
  //   clientId={process.env.REACT_APP_GOOGLE_APP_ID}
  //                    onSuccess={this.responseGoogle}
  //                    onFailure={this.responseGoogle}
  //                    className="googleLogin"
  //                    buttonText={this.props.google}
  //                    scope="profile"
  //                    cookiePolicy={'single_host_origin'}
  //      )   
  // }



  render() {
    let urlParams = new URLSearchParams(window.location.search);
    let resetSuccess = urlParams.get('reset') == "true";
    return (
      <div>
        {/* <input type="text"ref={this.fbRef}/>
        <button onClick={this.clickHandler}>Submit</button> */}
        {/* <!--popup form login--> */}
        <div id="login" style={{ display: "none" }} className="fancy popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">LOGIN</h2>
              <div className="style-form register-form">
                <form action="" id="myForm">
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" name="email" className="login-form" placeholder="Enter email" value={this.state.email} onChange={this.onChange} />
                    {this.state.emailValid ? <span style={{ color: "red" }}>{this.state.emailValid}</span> : ""}
                    {this.props.error.email ? <span style={{ color: "red" }}>{this.props.error.email}</span> : ""}
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" name="password" className="login-form" placeholder="Enter password" value={this.state.password} onChange={this.onChange} />
                    {this.props.error.password ? <span style={{ color: "red" }}>{this.props.error.password}</span> : ""}
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <button className="btn btn__blue btn__icon--r" id="login-submit" type="submit" >
                    LOG IN
                     <i className="icon-lock"></i>
                  </button>
                </form>
              </div>
              {/*<p className="register-text">OR</p>*/}
              <div className="register-soc">
                {/*<a className="register-facebook" id="fb-login" href="#"  ref={this.fbRef} >*/}
                    
                {/*<FacebookLogin
										appId={process.env.REACT_APP_FB_APP_ID}
										autoLoad={false}
										fields="name,email,picture"
										// onClick={componentClicked}
										callback={this.responseFacebook}
										cssClass="fbLogin"
										textButton={this.props.facebook}
									/>*/}
                  {/* <img src="images/icons/facebook-icon.png"    onClick={this.clickHandler} alt="" /> */}
                  {/*</a>*/}

                {/*<a className="register-google" href="#"><img src="images/icons/google-icon.png" alt="" /></a>*/}
              </div>
              <Forgetpass />
              <p className="popup-bottom__link"><a href="#" data-fancybox data-src="#forgot-password" >Forgot password?</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Login.propTypes = {
  loginJwt: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string,
  loginWithFacebook: PropTypes.func.isRequired,
  loginWithGoogle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  error: state.error
});

export default connect(mapStateToProps, { loginWithFacebook, loginWithGoogle, loginJwt })(withRouter(Login));
