import isEmpty from '../validation/is-empty';

import { SET_CURRENT_USER, SET_PROFILE_IMG, SHOW_NOTICE, SET_AUTH, CHANGE_USER_NICKNAME } from '../actions/types';

const initialState = {
	isAuthenticated: false,
	user: {},
	showNotice: false,
	alertMessage: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_USER:
			return {
				...state,
				// isAuthenticated: action.payload,
				user: action.payload
			};
		case SET_PROFILE_IMG:
			return {
				...state,
				// isAuthenticated: {
				// 	...state.isAuthenticated,
				// 	imageUrl: action.payload
				// },
				user: {
					...state.user,
					imageUrl: action.payload
				}
			};
		case SHOW_NOTICE:
			return {
				...state,
				showNotice: action.payload,
				alertMessage: action.message,
			};
		case SET_AUTH:
			return {
				...state,
				isAuthenticated: action.payload
			};

		default:
			return state;
	}
}