import React, { Component } from 'react'
import { Link as Link1, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { logoutUser, changeNickname } from '../../actions/authActions';
import PropTypes, { resetWarningCache } from 'prop-types';
import Login from '../../components/auth/login'
import Register from '../../components/auth/register'
import $ from 'jquery';
import { Link } from "react-scroll";
import S3 from 'react-aws-s3';
import axios from 'axios';
import { setProfileImg } from '../../actions/authActions';
import { getCurrentUser, isAuthenticated, changePassword } from '../../actions/authActions';

let backendServerURL = process.env.REACT_APP_URL;
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      name: '',
      input: {},
      errors: {},
      isValid: true
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    $('#logout-btn').click(this.handleLogout);
    $('#img-input').change(this.handleUpload);
    $('.name-form').change(this.onChange);
    $('#changenick-submit').click(this.handleNickChange);
    if (localStorage.jwtToken) {
      this.props.isAuthenticatedDispatch()
      this.props.fetchCurrentUserDispatch()
    }
    $('#changePassword_btn').click(this.handleSubmit);
    $('.change_Password').change(this.handleChange);
  }

  handleLogin = (e) => {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  onLogoutClick = event => {
    event.preventDefault();
    this.props.logoutUser(this.props.history);
  }

  handleLogout = event => {
    event.preventDefault();
    this.props.logoutUser(this.props.history);
  }

  handleTournamentRedirect = (event) => {
    event.preventDefault();
    window.location.replace("/#tournament-scroll")
  }

  handleNickChange = event => {
    event.preventDefault();
    const data = {
      name: this.state.name
    };
    this.props.changeNickname(data);

  }

  onChange = e => {

    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  validateField(fieldName, value) {
    let nameEmpty = this.state.nameEmpty;
    let errors = this.state.errors;
    let input = this.state.input;
    let isValid = this.state.isValid;
    let passwordlength = ""
    // debugger
    switch (fieldName) {
      case 'name':
        nameEmpty = value !== '' ? '' : 'Provide Name for user';
        break;
      case "oldPassword":
        passwordlength = value.length >= 6;
        errors["oldPassword"] = passwordlength ? '' : ' Password is too short. must be 6 characters long';
        isValid = false
        break;
      case "newPassword":
        passwordlength = value.length >= 6;
        errors["newPassword"] = passwordlength ? '' : ' Password is too short. must be 6 characters long';
        isValid = false
        break;
      case "confirm_password":
        passwordlength = value.length >= 6;
        errors["confirm_password"] = passwordlength ? '' : ' Password is too short. must be 6 characters long';

        if (typeof input["newPassword"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {

          if (this.state.input["newPassword"] != this.state.input["confirm_password"]) {
            errors["confirm_password"] = "Passwords don't match.";
            isValid = false
          } else { isValid = true }


        }
        break;
      default:
        isValid = true
        break;
    }
    this.setState({
      nameEmpty,
      errors: errors,
      isValid
    });
  }
  // Change Password Secton
  handleSubmit(event) {
    event.preventDefault();
    // console.log("this is the state", this.state.input);

    if (this.state.isValid) {
      console.log("this is the state", this.state.input);

      let input = this.state.input
      const oldPassword = input["oldPassword"]
      const newPassword = input["newPassword"]
      const newPassReq = {
        oldPassword,
        newPassword
      }
      this.props.changePasswordDispatch(newPassReq)
      console.log("this is the new pass req", newPassReq);

    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(prevState => ({
      input: {
        ...prevState.input,
        [name]: value
      }
    }), () => { this.validateField(name, value) })
  }
  // validate() {
  //   let input = this.state.input;
  //   let errors = {};
  //   let isValid = true;

  //   if (!input["oldPassword"]) {
  //     isValid = false;
  //     errors["oldPassword"] = "Please enter your Current Password";
  //   }

  //   if (!input["newPassword"]) {
  //     isValid = false;
  //     errors["newPassword"] = "Please enter your New password.";
  //   }

  //   if (!input["confirm_password"]) {
  //     isValid = false;
  //     errors["confirm_password"] = "Please enter your confirm password.";
  //   }

  //   if (typeof input["newPassword"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {

  //     if (input["newPassword"] != input["confirm_password"]) {
  //       isValid = false;
  //       errors["confirm_password"] = "Passwords don't match.";
  //     }
  //   }

  //   this.setState({
  //     errors: errors
  //   });

  //   return isValid;
  // }
  // change Password Section END



  handleUpload = async (event) => {
    event.preventDefault();
    const {
      state: {
        isOpen
      },
      props: {
        user,
        profileImgDispatch
      }
    } = this;
    const config = {
      // headers: {
      //   "Access-Control-Allow-Origin": "*", // Required for CORS support to work
      //   "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS 
      // },
      bucketName: 'playtooweb',
      region: 'us-east-2',
      accessKeyId: 'AKIA5Y36GLOTRS6KQCAB',
      secretAccessKey: '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',
    }

    if (event.target.files && event.target.files[0]) {
      let newFile = event.target.files[0];
      console.log("Upload Image", newFile)
      const ReactS3Client = new S3(config);

      // const newFileName = 'Profile Picture';
      await ReactS3Client
        .uploadFile(newFile)
        .then((data) => {
          const profileUrl = data.location
          const profile = {
            userId: user._id,
            imageUrl: profileUrl
          }
          console.log("this is img url", profileUrl, user)
          axios.post(
            backendServerURL + 'admin/updateUserImg',
            profile
          )
            .then(response => console.log("this is the response from update", response)).catch(err => console.log("this is the error from axios req", err))
          this.props.profileImgDispatch(profileUrl)
        }
        )
        .catch(err => console.error(err))
    }

  }
  render() {
    const {
      state: {
        isOpen,
      },
      props: {
        user,
        errors,
      }
    } = this;
    const self = this;
    return (

      <div>
        {/* <input id="imageInput" onChange={this.handleUpload} type="file" accept="image/*" /> */}


        <header>
          <div className="header-row">
            <a href="/" className="header-logo"><img src="images/logo.png" alt="image" /></a>
            <nav className="header-nav">
              {1 == 2 && this.props.auth && this.props.auth.isAuthenticated ?
                <>
                  <div className="header-menu" style={{ marginLeft: "95%" }}>
                    <ul>
                      <li>
                        <Link1 to={"/"} onClick={this.onLogoutClick}>Logout</Link1>
                      </li>
                    </ul>
                  </div>
                </>
                :

                <>
                  <ul className="header-menu">
                    <li><a href="#">Play</a></li>
                    {/*<li><Link1 to="sweepstake">Sweepstake</Link1></li>*/}
                    <li><Link1 to="/winner">Winners</Link1></li>
                    <li><a href="" data-fancybox data-src="#cash-prizes">HOW TO WIN</a></li>
                    <li>
                      <Link1 href="" onClick={(e) => this.handleTournamentRedirect(e)} to='/#tournament-scroll'>Tournament</Link1>
                    </li>

                    <li><a target="_blank" href="https://google.com">Developers</a></li>
                  </ul>
                  <div className="header-btn">
                    <a href="" className="btn btn__blue">Download app</a>
                    {!localStorage.jwtToken && <a href="" data-fancybox="#register-soc" data-src="#register-soc" className="btn btn__trans btn__icon">Register now <i className="icon-lock"></i></a>}
                    {!localStorage.jwtToken && <a
                      id="jkl"
                      // onClick={(e)=> this.handleLogin1(e)} 
                      href=""
                      data-fancybox="login-modal"
                      data-src="#login"
                      className="btn btn__gradient btn__icon">login <i className="icon-lock"></i></a>}

                  </div>

                  {localStorage.jwtToken && <div className="header-account">
                    <div className="account-money">
                      <img src="images/icons/coin-icon.png" alt="" />
                      0
                    </div>
                    <div className="account-money">
                      <img src="images/icons/money-icon.png" alt="" />
                      $0
                    </div>
                    <a data-fancybox="account-info" data-src="#popup-account" className="header-user"><img src={user && user.imageUrl ? user.imageUrl : "images/photos/photo-9.jpg"} alt="" /></a>
                  </div>}

                </>

              }
            </nav>
            <div className="burger"><span></span></div>


            <div> <Register /> </div>

            <div> <Login />  </div>
            <div>
              <div id="popup-account" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
                <div className="popup-box">
                  <div className="popup-top">
                    <div className="popup-top__img popup-top__img--picture">
                      <input id="img-input" onChange={this.handleUpload} type="file" accept="image/jpeg" />
                      <img src={user && user.imageUrl ? user.imageUrl : "images/games/game-1.jpg"} alt="" />


                    </div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-account__nickaname">
                      <div className="change-nickname">

                        <span data-fancybox="nickname" data-src="#new-nickname" className="btn-change__nickname"><i className="icon-pen-angled"></i></span>

                        <input type="text" value={user ? user.name : ''} disabled />
                      </div>
                      <p>{user ? user.email : ''}</p>
                    </div>
                    <h3 className="title-balance">BALANCE</h3>
                    <div className="wrap-balance">
                      <div className="box-balance-col">
                        <div className="box-balance box-balance__coin">
                          <img src="images/icons/coin-no-shadow-icon.png" alt="" />
                          <h4>LUCKY COINS</h4>
                          <p>0 <i className="icon-plus"></i></p>
                        </div>
                      </div>
                      <div className="box-balance-col" style={{ marginRight: '30px' }}>
                        <div className="box-balance box-balance__money">
                          <img src="images/icons/cash-icon.png" alt="" />
                          <h4>MONEY</h4>
                          {/*<p>$4.85 <i className="icon-plus"></i></p>*/}
                          <p>$0.0 <i className="icon-plus"></i></p>

                        </div>
                      </div>
                    </div>
                    <div className="popup-account__buttons">
                      <a href="#" className="btn btn__gray">HIGH SCORES</a>
                      <a href="#" className="btn btn__gradient">WITHDRAW</a>
                    </div>
                    <div className="popup-account__links">
                      <a href="#">WITHDRAW HISTORY</a>
                      <a href="#" data-fancybox="#change-password" data-src="#change-password">CHANGE PASSWORD</a>
                    </div>
                    <p className="link-bottom">
                      <a id="logout-btn" onClick={(e) => self.handleLogout(e)} href="#">LOG OUT</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--popup change password--> */}
              <div id="change-password" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
                <div className="popup-box">
                  <div className="popup-content">
                    <h2 className="popup-title popup-title__f45">CHANGE PASSWORD</h2>
                    <div className="style-form register-form">
                      <form action="">
                        <div className="field field-icon">
                          <i className="icon-lock"></i>
                          <input className="change_Password" id="password" name="oldPassword" value={this.state.input.oldPassword} type="password" placeholder="Current password" />
                          <span className="toggle-password icon-eye"></span>
                        </div>
                        <div style={{ color: "red" }}>{this.state.errors.oldPassword || errors}</div>
                        <div className="field field-icon">
                          <i className="icon-lock"></i>
                          <input className="change_Password" id="password" name="newPassword" value={this.state.input.newPassword} type="password" placeholder="New password" />
                          <span className="toggle-password icon-eye"></span>
                        </div>
                        <div style={{ color: "red" }}>{this.state.errors.newPassword}</div>
                        <div className="field field-icon">
                          <i className="icon-lock"></i>
                          <input className="change_Password" id="confirm_password" name="confirm_password" value={this.state.input.confirm_password} type="password" placeholder="Confirm password" />
                          <span className="toggle-password icon-eye"></span>
                        </div>
                        <div style={{ color: "red" }}>{this.state.errors.confirm_password}</div>
                        <button onClick={(e) => self.handleSubmit(e)} id="changePassword_btn" className="btn btn__blue btn__icon--r" type="submit">
                          confirm
                          <i className="icon-arrow-right"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--popup change password  END --> */}
              {/* nickname popup */}

              <div id="new-nickname" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
                <div className="popup-box">
                  <div className="popup-content">
                    <h2 className="popup-title popup-title__f45">new nickname</h2>
                    <div className="style-form register-form">
                      <form action="">
                        <div className="field">
                          <input id='nick-form' className='name-form' type="text" name='name' placeholder="Enter nickname" value={this.state.name} onChange={this.onChange} />
                          {this.state.nameEmpty ? <span style={{ color: "red" }}>{this.state.nameEmpty}</span> : ""}
                        </div>
                        <button onClick={(e) => self.handleNickChange(e)} id='changenick-submit' className="btn btn__blue btn__icon--r" type="submit">
                          confirm
                          <i className="icon-arrow-right"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* nickname popup */}


            </div>
            <div id="cash-prizes" style={{ display: "none" }} className="popup-style popup-style__w900">
              <div className="popup-box">
                <div className="popup-content cash-prizes tab-wrap">
                  <h2 className="popup-title popup-title__f45">cash prizes every day PLAY FOR <span>FREE</span></h2>
                  <ul className="tab-menu table-menu">
                    <li className="active"><span>SWEEPSTAKES</span></li>
                    <li><span>TOURNAMENTS</span></li>
                  </ul>
                  <div className="tab-content">
                    <div className="cash-prizes__row">
                      <div className="cash-prizes__l">
                        <img src="images/other/cash-img-1.png" alt="" />
                      </div>
                      <div className="cash-prizes__r">
                        <div className="cash-prizes__text">
                          <h2>FEELING LUCKY?</h2>
                          <h3>JOIN THE SWEEPSTAKES</h3>
                          <ul>
                            <li>Play games and earn “Lucky Coins”</li>
                            <li>The more Lucky Coin you have, the more chances you have to win our sweepstakes.</li>
                          </ul>
                        </div>
                        <div className="active-prize">
                          <div className="active-prize__text">
                            <h3>ACTIVE PRIZE POOL</h3>
                            <p>Next DRAW in: <i className="icon-timer"></i> 2d 18h 16m</p>
                          </div>
                          <div className="active-prize__bon">
                            $800 <img src="images/icons/cash-icon.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cash-bon">
                      <div className="cash-prizes__box">
                        <div className="cash-prizes__bonus">
                          <p>$250 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>1° Prize</h5>
                        </div>
                      </div>
                      <div className="cash-places">
                        <div className="place">
                          <p>$150 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>2°</h5>
                        </div>
                        <div className="place">
                          <p>$100 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>3°</h5>
                        </div>
                        <div className="place">
                          <p>$20 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>4 - 10°</h5>
                        </div>
                        <div className="place">
                          <p>$5 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>11 - 20°</h5>
                        </div>
                        <div className="place">
                          <p>$1 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>21 - 50°</h5>
                        </div>
                        <div className="place">
                          <p>$0.50 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>51 - 100°</h5>
                        </div>
                        <div className="place">
                          <p>$0.20 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>101 - 200°</h5>
                        </div>
                        <div className="place">
                          <p>$0.10 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>201 - 500°</h5>
                        </div>
                        <div className="place">
                          <p>$0.05 <img src="images/icons/cash-icon.png" alt="" /></p>
                          <h5>501 - 1000°</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="cash-prizes__row">
                      <div className="cash-prizes__l">
                        <img src="images/other/cash-img-2.png" alt="" />
                      </div>
                      <div className="cash-prizes__r">
                        <div className="cash-prizes__text">
                          <h2>FEELING STRONG?</h2>
                          <h3>COMPETE IN DAILY TOURNAMENTS</h3>
                          <ul>
                            <li>Win cash by placing yourself high in the score</li>
                            <li>The highest the score the highest the cash reward</li>
                            <li>Multiple positions get paid</li>
                          </ul>
                        </div>
                        <div className="active-prize">
                          <div className="active-prize__text">
                            <h3>WEEKLY JACKPOT</h3>
                            <p>Next JACKPOT in: <i className="icon-timer"></i> 2d 18h 16m</p>
                          </div>
                          <div className="active-prize__bon">
                            $950 <img src="images/icons/cash-icon.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tournament-info">
                      <div className="tournament-info__box">
                        <h3>TOURNAMENTS THIS WEEK</h3>
                        <p>43</p>
                      </div>
                      <div className="tournament-info__box">
                        <h3>AVERAGE JACKPOT</h3>
                        <p>$35 <img src="images/icons/cash-icon.png" alt="" /></p>
                      </div>
                    </div>
                  </div>
                  <div className="cash-prizes__bottom">
                    <div className="quick-tip">
                      <h2>QUICK TIP</h2>
                      <p>You can also collect Lucky Coins by playing in tournaments.</p>
                    </div>
                    <div className="popup-text">
                      <p>WHAT ARE YOU WAITING FOR?</p>
                    </div>

                    <a href="#" className="btn btn__gradient btn__icon--r">REGISTER NOW <i className="icon-person_add_alt"></i></a>
                    <p className="popup-bottom__link">AND GET YOUR WELCOME BONUS</p>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </header>
      </div>

    )
  }
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  changeNickname: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth ? state.auth.user : null,
  errors: state.error ? state.error.newPassword : null
});
const mapDispatchToProps = dispatch => ({
  profileImgDispatch: (data) => {
    dispatch(setProfileImg(data));
  },
  logoutUser: (data) => {
    dispatch(logoutUser(data));
  },
  changeNickname: (data) => {
    dispatch(changeNickname(data));
  },
  fetchCurrentUserDispatch: () => {
    dispatch(getCurrentUser());
  },
  isAuthenticatedDispatch: () => {
    dispatch(isAuthenticated(true));
  },
  changePasswordDispatch: (data) => {
    dispatch(changePassword(data));
  }

});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
// export default connect(mapStateToProps, mapDispatchToProps, { logoutUser })(withRouter(Header));