import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div>
                <footer>
    <div className="container">
        <div className="footer-row">
            <div className="footer-l">
                <a href="#" className="footer-logo">
                    <img src="images/logo.png" alt=""/>
                </a>
                <div className="soc">
                    <a href="#"><i className="icon-twitter"></i></a>
                    <a href="#"><i className="icon-youtube"></i></a>
                    <a href="#"><i className="icon-facebook"></i></a>
                    <a href="#"><i className="icon-instagram"></i></a>
                    <a href="#"><i className="icon-linkedin"></i></a>
                </div>
            </div>
            <div className="footer-widgets">
                <ul className="footer-menu">
                    <li><a href="#">Play</a></li>
                    <li><a href="#">How to win</a></li>
                    <li><a href="#">Tournaments</a></li>
                    <li><a href="#">Winners</a></li>
                </ul>
                <ul className="footer-menu">
                    <li><a href="#">Sweepstake Rules</a></li>
                    <li><a href="#">Tournament Rules</a></li>
                    <li><a href="#">Cookie Policy</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                </ul>
                <ul className="footer-menu">
                    <li><a href="#">Apply as developer</a></li>
                    <li><a href="#">Contact</a></li>
                    <li><a href="#">FAQ</a></li>
                    <li><a href="#">Suport</a></li>
                </ul>
            </div>
            <div className="copyright">Playtoo Inc. 2021 Copyright</div>
        </div>
    </div>
</footer>

            </div>
        )
    }
}
