// import axios from 'axios';
// import jwt_decode from 'jwt-decode';
import {
	PROFILE_LOADING,
	PROFILE_LOADED,
} from './types';


// let backendServerURL = process.env.REACT_APP_URL;

// import { reloadComponentWithSuccess } from '../common/lib.js';


export const setProfileLoading = () => {
	return {
		type: PROFILE_LOADING
	};
};


export const clearProfileLoading = () => {
	return {
		type: PROFILE_LOADED
	};
};
