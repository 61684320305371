import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import setAuthToken from './utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { setCurrentUser, logoutUser, forgotPassword } from './actions/authActions';
import configureStore from './store';

import Counter from './components/counter'
import dashboardRoute from './components/dashboard/dashboard';
import gameRoute from './components/games/game';
import game2Route from './components/games/game-2';
import game3Route from './components/games/game-3';
// import page1Route from './components/pages/page-1';
import page2Route from './components/pages/page-2';
import page3Route from './components/pages/page-3';
import loginRoute from './components/auth/login';
import forgetpassRoute from './components/auth/changePassword'
import signupRoute from './components/auth/signup';
import Popups from './components/popups';
import moment from "moment";


if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  //configureStore.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;

  // if (decoded.exp < currentTime) {
  //  configureStore.dispatch(logoutUser());
  // window.location.href = 'login';
  //}
}




function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={dashboardRoute} />
          <Route exact path="/sweepstake" component={page2Route} />
          <Route exact path="/winner" component={page3Route} />
          {/* <Route exact path="/tournament" component={page1Route} /> */}
          <Route exact path="/signin" component={loginRoute} />
          <Route exact path="/forgetpassword" component={forgetpassRoute} />
          <Route exact path="/signup" component={signupRoute} />
          <Route exact path="/popups" component={Popups} />
          <Route exact path="/gameplay" component={game3Route} />


        </Switch>
      </div>
    </Router>
  );
}

export default App;
