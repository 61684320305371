import React, { Component } from "react";
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import AlertMessage from '../../common/AlertMessage';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendPasswordRecoveryLink } from '../../actions/authActions'
import $ from 'jquery';


class ResendConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailValid: false,
    };
  }

  componentDidMount() {
   
    $('.forget-form').change(this.onChange);

    $('#forget-submit').click(this.onSubmit);

  }

  onSubmit = e => {
    e.preventDefault();

    const data = {
      email: this.state.email,
    };

    this.props.sendPasswordRecoveryLink(data, this.props.history);
  }

  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  validateField(fieldName, value) {
    let emailmatch = '';
    let emailValid = this.state.emailValid;
    switch (fieldName) {
      case 'email':
        emailmatch = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailValid = emailmatch ? '' : 'Email is invalid';
        break;
      default:
        break;
    }
    this.setState({
      emailValid,
    });
  }

  render() {
    let urlParams = new URLSearchParams(window.location.search);
    let emailSent = urlParams.get('success') == "true";
    // const { loading } = this.props.profile;
    // if (loading) {
    //   return <Spinner />;
    // }
    return (
      <div>

        {/* <!--popup form change password--> */}
        <div id="forgot-password" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">FORGOT PASSWORD</h2>
              <div className="style-form register-form">
                <form action="">
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" name="email" className="forget-form" placeholder="Enter email" value={this.state.email} onChange={this.onChange}  />
                    {this.state.emailValid ? <span style={{ color: "red" }}>{this.state.emailValid}</span> : ""}
                    {this.props.error.email ? <span style={{ color: "red" }}>{this.props.error.email}</span> : ""}
                  </div>
                  <button className="btn btn__blue" type="submit" id="forget-submit">
                    reset
                    </button>
                </form>
              </div>
              <p className="popup-bottom__link"><a href="#">We are gonna send you a confirmation email be sure
                to check your spam folder.</a></p>
            </div>
          </div>
        </div>

      </div>






    );
  }
}
ResendConfirmation.propTypes = {
  sendPasswordRecoveryLink: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  error: state.error
});

export default connect(mapStateToProps, { sendPasswordRecoveryLink })(withRouter(ResendConfirmation));