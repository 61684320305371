import axios from 'axios'
import { ADD_SCORE } from './types';


let backendServerURL = process.env.REACT_APP_URL;

export const sendScoreUpdate = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .post(
            `${backendServerURL}games/addScore`,
            payload,
            options
        )
        .then(res => {
            const { message, newScore } = res.data
            dispatch({ type: ADD_SCORE, payload: newScore})
            
            console.log(message)
        })
        .catch(err => console.log('Add Score Error..', err))
        
}