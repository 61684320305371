import { ADD_SCORE } from "../actions/types";

const initialState = {
    score: {}
}

export default function(state = initialState, action){
    switch(action.type){
        case ADD_SCORE: 
            return {
                ...state,
                score: action.payload
            }

        default:
            return state
    }
}