import axios from 'axios';
import { SWEEP_STAKE, SET_GAMES, SET_TOURNAMENTS } from './types';

let backendServerURL = "http://localhost:3001";

export const sweepStake = (payload) => dispatch => {
    console.log('Sweepstake Status Payload', payload)


    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}/admin/getSweepStakes`,
            payload,
            options
        )
        .then(res => {
           // debugger
            console.log('SWEEP STAKE RESPONSE', res);
            dispatch({ type: SWEEP_STAKE, payload: res })
        
        })
        .catch(err => {
           // debugger
            console.log(' SWEEPSTAKE RESPONSE ERR', err)

        })

};

export const getGames = (payload) => dispatch => {
    console.log('fetchGamesDispatch Status Payload', payload)


    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}/games`,
            payload,
            options
        )
        .then(res => {
           // debugger
            console.log('fetchGamesDispatch RESPONSE', res);
            dispatch({ type: SET_GAMES, payload: res && res.data ? res.data.games : null })
        
        })
        .catch(err => {
           // debugger
            console.log(' SWEEPSTAKE RESPONSE ERR', err)

        })

};

export const getTournaments = (payload) => dispatch => {
    console.log('getTournaments Status Payload', payload)


    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}/admin/getTournaments`,
            payload,
            options
        )
        .then(res => {
           // debugger
            console.log('getTournaments RESPONSE', res);
            dispatch({ type: SET_TOURNAMENTS, payload: res && res.data ? res.data.tournaments : null })
        
        })
        .catch(err => {
           // debugger
            console.log(' getTournaments RESPONSE ERR', err)

        })

};


