import React, { Component } from 'react'
import { connect } from 'react-redux';

import $ from 'jquery';
import { getGames } from '../../actions/sweepStake'
import { getCurrentUser } from '../../actions/authActions'
import { sendScoreUpdate } from '../../actions/games';

import Header from '../../common/header/header'

var j = $.noConflict()
let vPause = false;
class Game3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          gameName: "",
          gamePause: false,
          soundOn: true,
          score: 0,
          gameOver: false,
          
        };

        
        
    }

    componentDidMount() {
              
      
              const {
                props: {
                  user,
                  userId,
                  location: {
                    search,
                  },
                }
              } = this;
        this.props.fetchGamesDispatch()
        this.props.fetchCurrentUser()

        $('#pause-modal-close').click(this.handlePauseClose);
        if (search && search!== '') {
            this.setState({
                gameName: search.split("?gamename=").pop()
            });
        }
        $("#keep-play-button").click(function(){
          //alert( "Handler for .click() called." );
          console.log('keep playing modal open........')
          //$.fancybox('#keep-playing')  
          
        })
        
        $("#keep-play-button").trigger('click')

        

      //   $.fancybox({
      //     'autoScale': true,
      //     'transitionIn': 'elastic',
      //     'transitionOut': 'elastic',
      //     'speedIn': 500,
      //     'speedOut': 300,
      //     'autoDimensions': true,
      //     'centerOnScroll': true,
      //     'href' : '#contentdiv'
      //  })($.noConflict());
        
          
    }

    //handleShow = () => this.setState({gameOver: true});
    //handleClose = () => this.setState({show: false});

    pauseFunc = () => {
        const {
            state: {
                gameName,
            }
        } = this;
        console.log('pauseFunc')

        this.setState({
            gamePause: true,
        });
        vPause = !vPause;
        document.getElementById('myIframe').contentWindow.postMessage('pause-' + vPause, '*');
        // let  str1 = document.getElementById('t1')
        // let  str2 = document.getElementById('t2')
      
        // str1.style.display = "block";
        // str2.style.display = "block";
        document.getElementById("myIframe").contentWindow.pause(true);
        document.getElementById("myIframe").contentWindow.sound(false);
        window.location.href = `gameplay?gamename=${gameName}#pause-game-modal`;
    }

    resumeGame = () => {
      this.setState({
        gamePause: false,
    });
      
      
        document.getElementById("myIframe").contentWindow.pause(false);
        document.getElementById("myIframe").contentWindow.sound(true);
        //window.location = '/'    
    }

    gameEnd = () => {
      window.location = '/'    
    }

    handlePauseClose = event => {
        const {
            state: {
                gameName,
            }
        } = this;
        //window.location.href = `gameplay?gamename=${gameName}`;

        event.preventDefault();
    // this.props.logoutUser(this.props.history);
    console.log('handlePauseClose')

    this.resumeGame();
  }

  handleSound = (bool) => {
    console.log('handleSound bool', bool)
    this.setState({
        soundOn: bool,
    });
    document.getElementById("myIframe").contentWindow.sound(bool);
  }

 openFancybox = () => {
    //$('#keep-playing').fadeIn()
    document.getElementById('keep-playing').style.display = 'block'
    
  }

  loadIframe = (e) => {
    e.preventDefault()
    const {
      state: {
        gameOver,
        gameName
    },
        props: {
          user
        }
      } = this;
      
        console.log('CALLED loadIframe')
        // setTimeout(callGameStart, 10000);
        setTimeout(function(){
        console.log('callGameStart')
        //document.getElementById("myIframe").contentWindow.gameStart(true, '200')
        {user && user._id ? document.getElementById("myIframe").contentWindow.gameStart(true, user._id) : document.getElementById("myIframe").contentWindow.gameStart(true, '101')}

        }, 5000)

        if (window.addEventListener) {
             window.addEventListener("message",  (e) => {
              if(e.data.event_type === 'GameOver'){
                this.setState({gameOver: true})
                console.log('game over : ' + this.state.gameOver)
                $("#keep-play-button").trigger('click')
                

                // $.fancybox.open({
                //     src  : '#keep-playing',
                //     type : 'inline',
                //     opts : {
                //       onComplete : function() {
                //         console.info('done!');
                //       }
                //     }
                //   });

              //   $.fancybox({
              //     'autoScale': true,
              //     'transitionIn': 'elastic',
              //     'transitionOut': 'elastic',
              //     'speedIn': 500,
              //     'speedOut': 300,
              //     'autoDimensions': true,
              //     'centerOnScroll': true,
              //     'href' : '#contentdiv'
              //  });

                //this.openFancybox()
               // window.location.href = `gameplay?gamename=${gameName}#keep-playing`;

              }else{
                this.setState({score: e.data.event_data})
              
                const payload = {
                    gameScore: this.state.score,
                    gameName: this.state.gameName
                }

                this.props.sendCurrentScore(payload)
              }
              

              
              
            }, false);
            
          }

         else {
            window.attachEvent("onmessage", (e) => {
              
              this.setState({score: 0})
              
        }
        );
      }
        
     
        
    }

    game_start = (e) => {
        //e.preventDefault()
        // const {
        //     state: {
        //         gameName,
        //     }
        // } = this;
        const {
            
                state: {
                  gameName
                }
              } = this;

              
                // console.log('callGameStart')
                // //document.getElementById("myIframe").contentWindow.gameStart(true, '200')
                // {user && user._id ? document.getElementById("myIframe").contentWindow.gameStart(true, user._id) : document.getElementById("myIframe").contentWindow.gameStart(true, '101')}
        
                window.location.href = `gameplay?gamename=${gameName}`;

        //$("#myIframe").attr("src", `gameplay?gamename=${gameName}`);
        
    }

    render() {
        const {
            state: {
                gameName,
                gamePause,
                soundOn,
            },
            props: {
                games,
            }
        } = this;
        const self = this;
        const gameObj = games.find (obj => obj.name.replace(/ /g, "_").toLocaleLowerCase() == gameName)
        // console.log('gameIcon', gameIcon)
        const gameIcon = gameObj ? gameObj.icons[0].imageURL : 'images/games/game-15.jpg'
        return (
            <div>
                <Header />
                {/*<a style={{ display: "none" }} href="" data-fancybox="pause-game-modal" data-src="#pause-game"></a>*/}

                <div className="game-header">
                    {gamePause === false ? <button data-fancybox="pause-game-modal" data-src="#pause-game" id="game-pause-btn" onClick={this.pauseFunc} className={`${gamePause ? 'game-btn game-btn__close' : 'game-btn game-btn__pause'}`}>
                        <i className={`${gamePause ? 'icon-clear' : 'icon-pause'}`}></i>
                    </button> :
                    <button onClick={this.gameEnd} className={`${gamePause ? 'game-btn game-btn__close' : 'game-btn game-btn__pause'}`}>
                        <i className={`${gamePause ? 'icon-clear' : 'icon-pause'}`}></i>
                    </button> }
                    {gamePause && <p className="game-score text-center">PAUSED</p> }
                    {!gamePause && <p className="game-score text-center">SCORE:{this.state.score && this.state.score != 0 ? this.state.score : '0'} </p> }

                    {soundOn && <div onClick={() => this.handleSound(false)} className="game-btn game-btn__volume">
                        <i className="icon-volume"></i>
                    </div> }
                    {!soundOn && <div onClick={() => this.handleSound(true)} className="game-btn game-btn__volume">
                       <i className="icon-volume muted"></i>
                    </div> }

                    <a data-fancybox="keep-game-modal" data-src="#keep-playing" id="keep-play-button">
                        <i className={`${gamePause ? 'icon-clear' : 'icon-pause'}`}></i>
                    </a>
                    
                </div>
                <div className="game-content">
                    {/*<img src="images/other/game.jpg" alt="" />*/}
                    <iframe frameBorder="0" className="game-iframe" height="600" width="600" src={`././../${gameName}/index.html`} id="myIframe" onLoad={e => this.loadIframe(e)}></iframe>

                    
                </div>

                <div id="pause-game" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
                  <div className="popup-box">
                    <div className="popup-top">
                      <div className="popup-top__img popup-top__img--picture">
                        <img src={gameIcon} alt="" />
                      </div>
                    </div>
                    <div className="popup-content current-info">
                      <p className="current-score">Current Score: {this.state.score && this.state.score != 0 ? this.state.score : '0'} </p>
                      <p className="current-position">CURRENT POSITION: <span>{/*35th*/}</span></p>
                      <a id="pause-modal-close" onClick={(e) => self.handlePauseClose(e)} className="btn btn__blue btn-continue">CONTINUE  12</a>
                    </div>
                  </div>
                </div>

                {/*popup-keep playing*/}
                
                    <div id="keep-playing" style={{display: 'none'}} className="popup-style popup-style__text-center popup-style__w600">
                        <div className="popup-box">
                            <div className="popup-content">
                                <h2 className="popup-title popup-title__f45">KEEP PLAYING?</h2>
                                <a href="#" className="btn btn__icon--r btn__gradient btn-watch">Watch <i className="icon-video"></i></a>
                                <p className="popup-thank"><a data-fancybox="keep-playing-modal" data-src="#reward" id="keep-playing-btn">NO THANKS</a></p>
                            </div>
                        </div>
                    </div>  
                  

                {/*popup-keep playing*/}


                {/*popup-reward*/}

                <div id="reward" style={{display: 'none'}} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
                    <div className="popup-box">
                        <div className="popup-top">
                            <div className="popup-top__img popup-top__img--picture">
                                <img src="images/games/game-15.jpg" alt="" />
                            </div>
                        </div>
                        <div className="popup-content">
                            <div className="current-info">
                                <p className="reward-info">REWARD: 492 <img src="images/icons/coin-no-shadow-icon.png" alt=""/></p>
                                <a href="#" className="btn btn__gradient btn__icon--r btn-continue">DOUBLE IT <i className="icon-video"></i></a>
                            </div>
                            <p className="popup-thank mt20"><a data-fancybox="reward-modal" data-src="#lucky-coins" id="reward-btn">CLAIM</a></p>
                        </div>
                    </div>
                </div>

                {/*popup-reward*/}


                {/*popup-lucky coins*/}

                <div id="lucky-coins" style={{display: 'none'}} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
                    <div className="popup-box">
                        <div className="popup-top">
                            <div className="popup-top__img popup-top__img--picture">
                                <img src="images/games/game-15.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="popup-content ">
                            <h2 className="lucky-coins__title">GET LUCKY COINS <span className="line-effect"><img src="images/icons/coin-big-icon.png" alt=""/></span></h2>
                            <div className="popup-timer popup-timer__mb25">
                                <h5>NEXT DRAW:</h5>
                                <div className="timer">
                                    <div className="timer-box">
                                        <div className="timer-num">
                                            <span>0</span>
                                            <span>2</span>
                                        </div>
                                        <p>Days</p>
                                    </div>
                                    <div className="timer-box">
                                        <div className="timer-num">
                                            <span>2</span>
                                            <span>2</span>
                                        </div>
                                        <p>Hours</p>
                                    </div>
                                    <div className="timer-box">
                                        <div className="timer-num">
                                            <span>1</span>
                                            <span>2</span>
                                        </div>
                                        <p>Minutes</p>
                                    </div>
                                </div>
                            </div>
                            <button id='play-again' onClick={e => this.game_start(e)} className="btn btn__blue btn__icon--r btn-play">PLAY <i className="icon-play"></i></button>
                            <p className="highscore">Your Highscore: <span>3645pts</span> </p>
                        </div>
                    </div>
                </div>

                {/*popup-lucky coins*/}

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    fetchGamesDispatch: () => {
        dispatch(getGames());
    },
    fetchCurrentUser: () => {
      dispatch(getCurrentUser());
    },
    sendCurrentScore: (payload) => {
      dispatch(sendScoreUpdate(payload));
    },
});

const mapStateToProps = state => ({
  games: state.sweepstake.games ? state.sweepstake.games : [],
  user: state.auth ? state.auth.user : null,
});

export default connect(mapStateToProps, mapDispatchToProps)(Game3);
