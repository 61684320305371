import React, { Component } from 'react'
import Header from '../../common/header/header'

export default class Game extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="game-header">
    <button className="game-btn game-btn__pause">
        <i className="icon-pause"></i>
    </button>
    <p className="game-score">SCORE: <span>128pt</span></p>
    <div className="game-timer">
        <div className="popup-timer">
            <h5>Ends in:</h5>
            <div className="timer">
                <div className="timer-box">
                    <div className="timer-num">
                        <span>0</span>
                        <span>2</span>
                    </div>
                    <p>Days</p>
                </div>
                <div className="timer-box">
                    <div className="timer-num">
                        <span>2</span>
                        <span>2</span>
                    </div>
                    <p>Hours</p>
                </div>
                <div className="timer-box">
                    <div className="timer-num">
                        <span>1</span>
                        <span>2</span>
                    </div>
                    <p>Minutes</p>
                </div>
            </div>
        </div>
    </div>
    <div className="game-btn game-btn__volume">
        <i className="icon-volume"></i>
    </div>
</div>
<div className="game-content">
    <img src="images/other/game.jpg" alt=""/>
</div>

            </div>
        )
    }
}
