import {
	PROFILE_LOADING,
	PROFILE_LOADED,
} from '../actions/types';

const initialState = {
	loading: false,
};


export default function (state = initialState, action) {
	switch (action.type) {
		case PROFILE_LOADING:
			return {
				...state,
				loading: true
			};

		case PROFILE_LOADED:
			return {
				...state,
				loading: false
			};

		default:
			return state;
	}
}