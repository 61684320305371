export const INCREMENT = 'INCREMENT';

export const DECREMENT = 'DECREMENT';

export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const SET_USERS = 'SET_USERS';
export const SHOW_NOTICE = 'SHOW_NOTICE';
export const SWEEP_STAKE = 'SWEEP_STAKE'
export const TOURNAMENTS = 'TOURNAMENTS'
export const SET_GAMES = 'SET_GAMES';
export const SET_TOURNAMENTS = 'SET_TOURNAMENTS';
export const SET_PROFILE_IMG = 'SET_PROFILE_IMG';
export const SET_AUTH = 'SET_AUTH';
export const CHANGE_USER_NICKNAME = 'CHANGE_USER_NICKNAME';
export const ADD_SCORE = 'ADD_SCORE';
