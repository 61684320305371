import { SWEEP_STAKE, SET_GAMES, SET_TOURNAMENTS } from '../actions/types';

let initialState = {

};

export default function (state = initialState, action) {
    switch (action.type) {
        case SWEEP_STAKE:
            return {
                
                ...state,
                sweepstakes: action.payload
                
                
			};
        case SET_GAMES:
            return {
                
                ...state,
                games: action.payload
                
                
            };
        case SET_TOURNAMENTS:
            return {
                ...state,
                tournaments: action.payload
            };
        default:
            return state;
    }
}
