import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import rootReducer from './reducers';
export default function configureStore(initialState={}) {
 return createStore(
   rootReducer,
   initialState,
   composeWithDevTools(applyMiddleware(thunk))
  
 );
}




