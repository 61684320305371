import {combineReducers} from 'redux';
import counterReducer from './counterReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import sweepStakeReducer from './sweepStakeReducer'
import tournamentReducer from './tournamentReducer'
import gameReducer from './gameReducer'

export default combineReducers({
	counter:counterReducer,
	auth: authReducer,
	profile: profileReducer,
	error: errorReducer,
	sweepstake:sweepStakeReducer,
	tournament:tournamentReducer,
	game:gameReducer,

});