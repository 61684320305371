import React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faTimes } from '@fortawesome/free-solid-svg-icons'


class AlertMessage extends React.Component {
	render() { 
		let {style, type} = this.props;

		return (
			<div style={{backgroundColor: "green"}} role="alert">
				{this.props.children}
				<button type="button" className="close" data-dismiss="alert" aria-label="Close">
					{/* <span aria-hidden="true"><FontAwesomeIcon icon={faTimes} /></span> */}
				</button>
			</div>
		);
	}
}

export default AlertMessage;
