import React, { Component } from "react";
import PropTypes from 'prop-types';
import Header from '../../common/header/header';
import Spinner from '../../common/Spinner';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions'
import $ from 'jquery';



class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      message: '',
      imageURL: '',
      nameValid: false,
      emailValid: false,
      passwordValid: false,
      agree: false
    };
  }



  componentDidMount() {

    $('#reg-submit').click(this.onSubmit);

    $('.reg-form').change(this.onChange);


  }

  onSubmit = e => {
    // debugger
    e.preventDefault();
    const newUser = {
      image: this.state.imageURL,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
    };
    console.log(newUser, "data")
    this.props.registerUser(newUser, this.props.history);
  }
  onChange = e => {
    if (e.target.files && e.target.files[0]) {
      let newFile = e.target.files[0];
      console.log("Upload Image", newFile)
      this.setState({ imageURL: newFile },
      )
    }
    // debugger
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  checkboxHandler = () => {
    this.setState({ agree: !this.state.agree });
  }

  validateField(fieldName, value) {
    let emailmatch = '';
    let emailValid = this.state.emailValid;
    let passwordlength = '';
    let passwordValid = this.state.passwordValid;
    let namelength = '';
    let nameValid = this.state.nameValid;
    switch (fieldName) {
      case 'email':
        emailmatch = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        console.log(emailmatch)
        emailValid = emailmatch ? '' : 'Email is invalid';
        break;
      case 'password':
        passwordlength = value.length >= 6;
        passwordValid = passwordlength ? '' : ' Password is too short. must be 6 characters long';
        break;
      case 'name':
        namelength = value.length >= 6;
        nameValid = namelength ? '' : ' Name must be 7 characters long';
        break;
      default:
        break;
    }
    this.setState({
      emailValid,
      passwordValid,
      nameValid,
    });
  }

  render() {
    // const { loading } = this.props.profile;
    // if (loading) {
    //   return <Spinner />;
    // }
    return (
      <div>

        {/* <!--popup form register--> */}
        <div id="register-form" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">PLAY GAMES AND EARN CASH</h2>
              <div className="style-form register-form">
                <form action="">
                  <label className="avatar">
                    <input id="input-avatar" className="reg-form" type="file" />
                    <img id="avatar" src="" alt="" />
                    <span className="avatar-text">
                      <i className="plus"></i>
                      Choose avatar or upload one
                    </span>
                  </label>
                  <div className="field field__icon">
                    <i className="icon-user"></i>
                    <input type="text" name="name" className="reg-form" placeholder="Full Name" value={this.state.name} onChange={this.onChange} />
                    {this.state.nameValid ? <span style={{ color: "red" }}>{this.state.nameValid}</span> : ""}
                  </div>
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" name="email" className="reg-form" placeholder="Enter email" value={this.state.email} onChange={this.onChange} />
                    {this.state.emailValid ? <span style={{ color: "red" }}>{this.state.emailValid}</span> : ""}
                    {this.props.error.emailRegister ? <span style={{ color: "red" }}>{this.props.error.emailRegister}</span> : ""}
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" name="password" className="reg-form" placeholder="Enter password" value={this.state.password} onChange={this.onChange} />
                    {this.state.passwordValid ? <span style={{ color: "red" }}>{this.state.passwordValid}</span> : ""}
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" placeholder="Confirm password" />
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <button className="btn btn__blue btn__icon--r" id="reg-submit" type="submit" >
                    confirm
                    <i className="icon-arrow-right"></i>
                  </button>
                </form>
              </div>
              <p className="popup-bottom__link"><a href="#">By registering you accept the Contest Rules and Privacy Policy</a></p>
            </div>
          </div>
        </div>

      </div>

    );
  }
}

SignUp.propTypes = {
  registerUser: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  error: state.error
});

export default connect(mapStateToProps, { registerUser })(withRouter(SignUp));
