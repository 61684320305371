import { TOURNAMENTS } from '../actions/types';

let initialState = {

};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOURNAMENTS:
            return {
				...state,
                

                
			};
        default:
            return state;
    }
}
